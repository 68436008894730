<template>
  <div class="wrapper">
    <aside class="steps_menu">
      <div class="logo">
        <router-link to="/dashboard">
          <img class="logonew" src="@/assets/images/logo2.png" />
          <!-- <img src="@/assets/images/logo.svg">
                    <img class="logo_text" src="@/assets/images/logo_text.svg"> -->
        </router-link>
      </div>
      <!--
            blocks:{"form1":{"active":true ,
            "validator":"legal_informationForm" ,
            "label":'Legal Information' ,
             "formIsValid":false} ,
             clientData.statusId <=1
             -->

      <div class="registration_menu">
        <ul id="progressbar">
          <template v-if="checkClientStatus()">
            <li
              @click="
                show_block = 1;
                togleBlock(true);
              "
              :class="{
                active: show_block >= 1,
                completed: blocks.form1.formIsValid,
              }"
            >
              <p><span class="legal"></span>General Information</p>
            </li>
            <li
              @click="
                show_block = 2;
                togleBlock(true);
              "
              :class="{
                active: show_block >= 2,
                completed: blocks.form1.formIsValid && blocks.form2.formIsValid,
              }"
            >
              <p><span class="business"></span>Business Information</p>
            </li>
            <li
              @click="
                show_block = 3;
                togleBlock(true);
              "
              :class="{
                active: show_block >= 3,
                completed:
                  blocks.form1.formIsValid &&
                  blocks.form2.formIsValid &&
                  blocks.form3.formIsValid,
              }"
            >
              <p><span class="owner"></span>Responsible Parties</p>
            </li>
            <li
              @click="
                show_block = 4;
                togleBlock(true);
              "
              :class="{
                active: show_block >= 4,
                completed:
                  blocks.form1.formIsValid &&
                  blocks.form2.formIsValid &&
                  blocks.form3.formIsValid &&
                  blocks.form4.formIsValid,
              }"
            >
              <p><span class="states"></span>Registered States</p>
            </li>
          </template>
          <template v-else>
            <li
              :class="{
                active: show_block >= 1,
                completed: blocks.form1.formIsValid,
              }"
            >
              <p><span class="legal"></span>General Information</p>
            </li>
            <li
              :class="{
                active: show_block >= 2,
                completed: blocks.form1.formIsValid && blocks.form2.formIsValid,
              }"
            >
              <p><span class="business"></span>Business Information</p>
            </li>
            <li
              :class="{
                active: show_block >= 3,
                completed:
                  blocks.form1.formIsValid &&
                  blocks.form2.formIsValid &&
                  blocks.form3.formIsValid,
              }"
            >
              <p><span class="owner"></span>Responsible Parties</p>
            </li>
            <li
              :class="{
                active: show_block >= 4,
                completed:
                  blocks.form1.formIsValid &&
                  blocks.form2.formIsValid &&
                  blocks.form3.formIsValid &&
                  blocks.form4.formIsValid,
              }"
            >
              <p><span class="states"></span>Registered States</p>
            </li>
          </template>
        </ul>
        <figure>
          <img src="@/assets/images/registration_img.svg" alt="registration" />
        </figure>
      </div>
    </aside>

    <div class="body_section register_form">
      <profileHeader
        :showFilter="true"
        @changedGlobalclient="changedGlobalclient"
      />
      <div class="body_content">
        <div class="main_content">
          <div id="legal_information" v-if="blocks.form1.active">
            <!--v-slot="{ invalid, validated, handleSubmit, validate }"-->
            <ValidationObserver ref="legal_informationForm">
              <div class="form_title">
                <h3>General Information</h3>
                <p>
                  *Please ensure the information on this form is correct,
                  including spelling, this is the exact information we give to
                  the states
                </p>
                <div class="form_section">
                  <form>
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <label class="form_label"
                            >Legal Name Of Business</label
                          >
                          <ValidationProvider
                            name="Business Name"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <input
                              v-model="Payload.busName"
                              type="text"
                              @blur="autoSave"
                              class="form-control"
                            />
                            <span
                              class="v-messages error-msg"
                              v-if="errors[0]"
                              >{{ errors[0] }}</span
                            >
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <label class="form_label">Trade Name (DBA)</label>
                          <ValidationProvider
                            name="Trade Name"
                            rules="max:50"
                            v-slot="{ errors }"
                          >
                            <input
                              v-model="Payload.tradeName"
                              type="text"
                              @blur="autoSave"
                              class="form-control"
                            />
                            <span
                              class="v-messages error-msg"
                              v-if="errors[0]"
                              >{{ errors[0] }}</span
                            >
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="form_section white_bg">
                <form>
                  <div>
                    <h4 class="title">Legal Business Address</h4>

                    <addressComponent
                      v-bind:autoSave="autoSave"
                      v-if="ClientDataloaded"
                      v-bind:address="Payload.busAddress"
                      v-bind:addressType="'add'"
                    />
                  </div>

                  <span class="devider"></span>
                  <div>
                    <h4 class="title">Mailing Address</h4>
                    <div class="row">
                      <div class="col d-flex">
                        <div class="form-group">
                          <label class="radio_label"
                            >Use same as above

                            <input
                              type="radio"
                              :value="true"
                              name="mailingAddress"
                              v-model="mailingAddressAsSame"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div class="form-group">
                          <label class="radio_label"
                            >Enter new address
                            <input
                              type="radio"
                              :value="false"
                              name="mailingAddress"
                              v-model="mailingAddressAsSame"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div class="form-group">
                          <label class="radio_label"
                            >Use TaxHero as mailing address
                            <input
                              type="radio"
                              :value="null"
                              name="mailingAddress"
                              v-model="mailingAddressAsSame"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="mailingAddressAsSame === true">
                    <addressComponent
                      v-bind:autoSave="autoSave"
                      v-if="ClientDataloaded"
                      v-bind:address="Payload.mailingAddress"
                      v-bind:addressType="'mailingSameAbove'"
                      :isDisabled="true"
                      :key="'mailingSameAboveAddressComponent'"
                    />
                  </div>
                  <div v-if="mailingAddressAsSame === false">
                    <addressComponent
                      v-bind:autoSave="autoSave"
                      v-if="ClientDataloaded"
                      v-bind:address="Payload.mailingAddress"
                      v-bind:addressType="'mailing'"
                      :key="'mailingAddressComponent'"
                    />
                  </div>
                  <div v-if="mailingAddressAsSame === null">
                    <addressComponent
                      v-bind:autoSave="autoSave"
                      v-if="ClientDataloaded"
                      v-bind:address="Payload.mailingAddress"
                      v-bind:addressType="'mailingTaxhero'"
                      :isDisabled="mailingAddressAsSame === null"
                      :key="'mailingTaxheroAddressComponent'"
                    />
                  </div>
                  <!-- <span class="devider"></span>
                  <div>
                    <h4 class="title">Location Of Tax Records</h4>

                    <addressComponent
                      v-bind:autoSave="autoSave"
                      v-if="ClientDataloaded"
                      v-bind:address="Payload.taxRecordLocation"
                      v-bind:addressType="'tax_address'"
                    />
                  </div> -->
                </form>
              </div>
            </ValidationObserver>
          </div>
          <div id="business_information" v-if="blocks.form2.active">
            <ValidationObserver ref="business_information">
              <div class="form_title">
                <h3>Business</h3>
                <p>
                  *Please ensure the information on this form is correct,
                  including spelling, this is the exact information we give to
                  the states
                </p>
                <div class="form_section">
                  <form>
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <label class="form_label"
                            >Business Email/Shared Mailbox

                            <span>
                              <!-- <a
                                :id="'remarks1' + index"
                                class="download_form"
                                @click="download_file(item['documents'][0])"
                                ><b-icon
                                  icon="download"
                                  aria-hidden="true"
                                ></b-icon
                                >Download</a
                              >

                              <b-tooltip :target="'remarks1' + index">
                                <p v-if="UpdateAdjustMentAmount(item) != 0">
                                  Difference Amount:
                                  {{ UpdateAdjustMentAmount(item) }}
                                </p>
                                <p
                                  v-if="checkProperty(item, 'comment') != '--'"
                                >
                                  {{ checkProperty(item, "comment") }}
                                </p>
                              </b-tooltip> -->
                              <img
                                :id="'busEmailMailbox'"
                                style="width: 14px; margin-top: -4px;"
                                src="@/assets/images/info_icon.svg"
                              />

                              <b-tooltip
                                :target="'busEmailMailbox'"
                                data-html="true"
                              >
                                <p style="width: 350px">
                                  Please create a new dedicated email address
                                  for sales tax using your domain
                                  (salestax@yourcompanydomainnamehere.com).
                                </p>
                              </b-tooltip>
                            </span>
                          </label>
                          <ValidationProvider
                            name="Business email"
                            rules="required|email"
                            v-slot="{ errors }"
                          >
                            <input
                              v-model="Payload.busEmail"
                              type="text"
                              @blur="autoSave"
                              class="form-control"
                            />
                            <span
                              class="v-messages error-msg"
                              v-if="errors[0]"
                              >{{ errors[0] }}</span
                            >
                          </ValidationProvider>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group date_desable">
                          <label class="form_label"
                            >Business Phone Number</label
                          >
                          <ValidationProvider
                            name="Business Phone Number"
                            rules="required|phonenumber"
                            v-slot="{ errors }"
                          >
                            <VuePhoneNumberInput
                              default-country-code="US"
                              :no-example="true"
                              :only-countries="['US']"
                              :no-country-selector="true"
                              @update="updatePhoneNumber1"
                              :required="true"
                              v-model="Payload.busPhone"
                            />
                            <span
                              class="v-messages error-msg"
                              v-if="errors[0]"
                              >{{ errors[0] }}</span
                            >
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <label class="form_label"
                            >Business Representative Name</label
                          >

                          <ValidationProvider
                            name="Business Representative Name"
                            rules="required|max:50"
                            v-slot="{ errors }"
                          >
                            <input
                              v-model="Payload.busContact.name"
                              type="text"
                              @blur="autoSave"
                              class="form-control"
                            />
                            <span
                              class="v-messages error-msg"
                              v-if="errors[0]"
                              >{{ errors[0] }}</span
                            >
                          </ValidationProvider>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group date_desable">
                          <label class="form_label"
                            >Representative Phone Number</label
                          >
                          <ValidationProvider
                            name="Representative Phone Number"
                            rules="required|phonenumber"
                            v-slot="{ errors }"
                          >
                            <VuePhoneNumberInput
                              default-country-code="US"
                              :no-example="true"
                              :only-countries="['US']"
                              :no-country-selector="true"
                              @update="updatePhoneNumber"
                              :required="true"
                              v-model="Payload.busContact.phone"
                            />
                            <span
                              class="v-messages error-msg"
                              v-if="errors[0]"
                              >{{ errors[0] }}</span
                            >
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="form-group date_desable">
                          <label class="form_label"
                            >Business Representative Email</label
                          >
                          <ValidationProvider
                            name="Business Representative email"
                            rules="required|email"
                            v-slot="{ errors }"
                          >
                            <input
                              v-model="Payload.busContact.email"
                              type="text"
                              @blur="autoSave"
                              class="form-control"
                            />
                            <span
                              class="v-messages error-msg"
                              v-if="errors[0]"
                              >{{ errors[0] }}</span
                            >
                          </ValidationProvider>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group date_desable">
                          <label class="form_label">Business Website</label>
                          <input
                            v-model="Payload.busContact.website"
                            type="text"
                            @blur="autoSave"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <!--
                                        <div class="col">
                                            <div class="form-group">
                                                <label class="form_label">Federal Employer ID</label>

                                               <ValidationProvider name="Federal Employer ID" rules="required|min:4|max:15" v-slot="{ errors }">
                                                    <input v-model="Payload.fedaralEmpId"  type="text"
                                                    @blur="autoSave" class="form-control">
                                                    <span  class="v-messages error-msg" v-if="errors[0]">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                            </div>
                                        </div> -->
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <label class="form_label"
                            >Date of Incorporation/Formation</label
                          >
                          <div
                            id="duedate"
                            class="input-group date"
                            data-date-format="dd-mm-yyyy"
                          >
                            <ValidationProvider
                              name="Date of Incorporation/Formation"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <datepicker
                                :open-date="new Date(openDate)"
                                :disabled-dates="{
                                  to: new Date(2000, 0, 1), // Disable all dates up to specific date
                                  from: new Date(), // Disable all dates after specific date
                                }"
                                :required="true"
                                format="yyyy-MM-dd"
                                v-model="Payload.busFormatedOn"
                                name="uniquename"
                                typeable="true"
                              ></datepicker>

                              <span class="input-group-addon"
                                ><i class="glyphicon glyphicon-calendar"></i
                              ></span>
                              <span
                                class="v-messages error-msg"
                                v-if="errors[0]"
                                >{{ errors[0] }}</span
                              >
                            </ValidationProvider>
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label class="form_label">EIN</label>
                          <ValidationProvider
                            name="EIN"
                            rules="min:10|max:11"
                            v-slot="{ errors }"
                          >
                            <input
                              v-model="Payload.EIN"
                              type="text"
                              @blur="autoSave"
                              @input="formatEinInput"
                              class="form-control"
                            />
                            <span
                              class="v-messages error-msg"
                              v-if="errors[0]"
                              >{{ errors[0] }}</span
                            >
                          </ValidationProvider>
                        </div>
                      </div>

                      <div class="col">
                        <div class="form-group">
                          <label class="form_label">Incorporation State</label>

                          <ValidationProvider
                            name="Incorporation State"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <multiselect
                              v-model="Payload.incorporatedStateDetails"
                              :value="'_id'"
                              :options="usstateList"
                              :searchable="true"
                              :close-on-select="true"
                              :allow-empty="true"
                              @input="updateIncorporatedState"
                              label="name"
                              placeholder="Select Incorporation State"
                              track-by="name"
                            >
                            </multiselect>
                            <!-- <select :name="addressType+' Country'" v-model="address.cntryId" id="country" class="selectpicker"  data-live-search="false">
                                                        <template v-for="(country ,ind) in countryList"  >
                                                            <option :key="ind" :value="country._id" >{{country.name}}</option>
                                                        </template>

                                                    </select> -->
                            <span
                              class="v-messages error-msg"
                              v-if="errors[0]"
                              >{{ errors[0] }}</span
                            >
                          </ValidationProvider>

                          <!-- <p v-if="checkCaliforniaState"><i></i>*California requires Articles of Organization and copies of Driver’s License</p>-->
                        </div>
                      </div>
                      <!-- {{Payload.busStructureDetails}}
                                        ---------
                                        {{bus_structure}} -->
                      <!-- dsfasfasfsadf -->
                    </div>
                    <div class="row">
                      <div class="col">
                        <ValidationProvider
                          name="Business structure"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <div class="form-group">
                            <label class="form_label">Entity Type</label>
                            <BaseRadioButtonGroup
                              :options="bus_structure"
                              v-model="Payload.busStructureDetails"
                              @input="updateBusStructure"
                              :selectedValue="
                                Payload.busStructureDetails &&
                                  Payload.busStructureDetails._id
                              "
                              name="bus_structure"
                            />
                            <input
                              v-model="Payload.busStructureDetailsOther"
                              type="text"
                              @blur="autoSave"
                              class="form-control"
                              v-if="
                                Payload.busStructureDetails &&
                                  Payload.busStructureDetails._id == 6
                              "
                            />
                            <span
                              class="v-messages error-msg"
                              v-if="errors[0]"
                              >{{ errors[0] }}</span
                            >
                          </div>
                        </ValidationProvider>
                      </div>
                    </div>
                    <label class="form_label">Secretary of State Number</label>
                    <p style="margin-bottom: 15px;">
                      Input all numbers and states by clicking “More” – Ex.
                      CA-123456789
                    </p>
                    <template
                      v-for="(item, indx) in Payload.secretaryStateNumbers"
                    >
                      <div class="row" :key="indx">
                        <div class="col">
                          <div class="form-group">
                            <ValidationProvider
                              name="Secretary State Number"
                              rules="required|max:20"
                              v-slot="{ errors }"
                            >
                              <input
                                v-model="item.number"
                                type="text"
                                @blur="autoSave"
                                class="form-control"
                              />
                              <span
                                class="v-messages error-msg"
                                v-if="errors[0]"
                                >{{ errors[0] }}</span
                              >
                            </ValidationProvider>
                          </div>
                          <span
                            v-if="Payload.secretaryStateNumbers.length > 1"
                            class="delete_btn delete_btn2"
                            style="right: -35px; top: 4px"
                            @click="removesecretaryStateNumber(indx)"
                            ><b-icon icon="trash" aria-hidden="true"></b-icon
                          ></span>
                        </div>
                      </div>
                    </template>
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <span
                            class="primary_btn add_more d-inline-flex w-auto "
                            @click="addsecretaryStateNumber()"
                            >+More</span
                          >
                        </div>
                      </div>
                    </div>

                    <!--{ "_id": 3924, "name": "California", "cntryId": 231, "sName": "CA" }-->
                    <div class="row" v-if="checkCaliforniaState">
                      <div class="col">
                        <div class="form-group">
                          <label class="form_label"
                            >Articles of Organization</label
                          >
                          <div class="upload_sec">
                            <uploadFile
                              @onUploadComplete="onUploadComplete"
                              :allowMultiple="true"
                              :acceptedFiles="'*'"
                              :indexValue="0"
                              :takeIndex="false"
                              :payLoadKey="'articlesOfOrgAndDriverLiecenses'"
                              :payLoadDocumentsKey="''"
                            />
                          </div>
                          <ul class="uploaded-list">
                            <template
                              v-for="(other,
                              indx) in Payload.articlesOfOrgAndDriverLiecenses"
                            >
                              <li :key="indx">
                                {{ other.name }}
                                <span
                                  @click="
                                    removeDoc(
                                      indx,
                                      Payload.articlesOfOrgAndDriverLiecenses
                                    )
                                  "
                                  >X</span
                                >
                              </li>
                            </template>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <template v-if="checkProperty(Payload, 'busStructureId') == 3">
                <div class="form_section white_bg">
                  <form>
                    <h6>
                      What have you elected to be treated as for Federal Income
                      Tax Purposes?
                    </h6>

                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <!-- <input type="text"
                           @blur="autoSave" class="form-control">-->

                          <ValidationProvider
                            name="Entity Type"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <multiselect
                              v-model="Payload.llcTypeDetails"
                              :options="llc_type"
                              :searchable="true"
                              :close-on-select="true"
                              :allow-empty="true"
                              @input="updatellcTypes"
                              label="name"
                              value="value1"
                              placeholder="Select the Entity Type"
                              track-by="name"
                              :multiple="true"
                            >
                              <template
                                slot="selection"
                                slot-scope="{ values, isOpen }"
                              >
                                <span
                                  class="multiselect__selectcustom"
                                  v-if="values.length && !isOpen"
                                  >{{ values.length }} selected</span
                                >
                                <span
                                  class="multiselect__selectcustom"
                                  v-if="values.length && isOpen"
                                ></span>
                              </template>
                            </multiselect>
                            <!-- <select :name="addressType+' Country'" v-model="address.cntryId" id="country" class="selectpicker"  data-live-search="false">
                                                            <template v-for="(country ,ind) in countryList"  >
                                                                <option :key="ind" :value="country._id" >{{country.name}}</option>
                                                            </template>

                                                        </select> -->
                            <span
                              class="v-messages error-msg"
                              v-if="errors[0]"
                              >{{ errors[0] }}</span
                            >
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>

                    <!-- <ul class="form_list">

                                <template v-for="(llc ,index) in llc_type">
                                    <li  :key="index"  >

                                        <input class="styled-checkbox"  @click="checkLlc($event,llc)" :id="'llc_'+index"  v-model="llc.selected" type="checkbox" value="value1">
                                        <label :for="'llc_'+index">{{llc.name}}</label>
                                    </li>
                                </template>

                                </ul> -->
                  </form>
                </div>
              </template>
              <div class="form_section">
                <form>
                  <div>
                    <h4 class="title">Accounting Method</h4>
                    <div class="row">
                      <!----previousFilingEndDate---->
                      <!-- <div class="col">
                        <div class="form-group">
                          <label class="form_label"
                            >Previous Filing End Date</label
                          >

                          <div
                            id="previousFilingEndDate"
                            class="input-group date"
                            data-date-format="dd-mm-yyyy"
                          >
                            <ValidationProvider
                              name="Previous Filing End Date"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <datepicker
                                :open-date="new Date(openDate)"
                                :disabled-dates="{
                                  from: new Date(startEligibleDate),
                                }"
                                :required="true"
                                format="yyyy-MM-dd"
                                v-model="Payload.previousFilingEndDate"
                                name="previousFilingEndDate"
                              ></datepicker>

                              <span class="input-group-addon"
                                ><i class="glyphicon glyphicon-calendar"></i
                              ></span>
                              <span
                                class="v-messages error-msg"
                                v-if="errors[0]"
                                >{{ errors[0] }}</span
                              >
                            </ValidationProvider>
                          </div>
                        </div>
                      </div> -->

                      <div class="col">
                        <div class="form-group">
                          <label class="form_label"
                            ><b>Accounting Period Year End Date</b></label
                          >
                          <p style="margin-bottom: 15px;     color: #19213e;">
                            For most companies, there accounting period year end
                            date is 12/31. If unsure, please refer to your
                            previous year's tax return.
                          </p>

                          <ValidationProvider
                            name="TaxPeriodbeginning"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <multiselect
                              v-model="Payload.taxBegginingPeriodDetails"
                              :value="'id'"
                              :options="allMonths"
                              :searchable="true"
                              :close-on-select="true"
                              :allow-empty="false"
                              :multiple="false"
                              label="name"
                              placeholder="Select Tax period beginning"
                              track-by="name"
                              @input="updatetaxBegginingPeriodDetails"
                            >
                            </multiselect>
                            <span
                              class="v-messages error-msg"
                              v-if="errors[0]"
                              >{{ errors[0] }}</span
                            >
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                    <!--  <div class="row">-->
                    <!-- <div class="col">
                                            <div class="form-group">
                                                <label class="form_label">Accounting Closes On</label>
                                                <div id="datefiling" class="input-group date" data-date-format="dd-mm-yyyy">

                                                   <ValidationProvider name="Accounting close date" rules="required" v-slot="{ errors }">
                                                        <datepicker :disabledDates="disabledDates" :required="true" :format="'MM-dd'" v-model="Payload.accClosedOn" name="uniquename"></datepicker>

                                                        <span class="input-group-addon"><i class="glyphicon glyphicon-calendar"></i></span>
                                                        <span  class="v-messages error-msg" v-if="errors[0]">{{ errors[0] }}</span>
                                                  </ValidationProvider>

                                                </div>
                                            </div>
                                        </div> -->
                    <!-----Nexus (Physical/Economical/Both)

                                        <div class="col">
                                            <div class="form-group">
                                                <label class="form_label">Nexus (Physical/Economical/Both)</label>
                                                <ValidationProvider name="Nexus" rules="required" v-slot="{ errors }">
                                                    <multiselect
                                                        v-model="Payload.nexusTypeDetails"
                                                        :value="'_id'"
                                                        :options="nexus"
                                                        :searchable="true"
                                                        :close-on-select="true"
                                                        :allow-empty="false"
                                                        :multiple="false"
                                                        label="name"
                                                        placeholder="Select Nexus"
                                                        track-by="name"
                                                        @input="updateNexusTypeDetails"


                                                        >
                                                    </multiselect>

                                                    <span  class="v-messages error-msg" v-if="errors[0]">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                        ----->
                    <!--  </div>-->
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <label class="form_label"
                            >Description of Items Sold</label
                          >
                          <ValidationProvider
                            name="Description of Items Sold"
                            rules="required|max:100"
                            v-slot="{ errors }"
                          >
                            <input
                              type="text"
                              @blur="autoSave"
                              name="itemDescription"
                              v-model="Payload.itemDescription"
                              class="form-control"
                            />
                            <span
                              class="v-messages error-msg"
                              v-if="errors[0]"
                              >{{ errors[0] }}</span
                            >
                          </ValidationProvider>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label class="form_label"
                            >NAICS Code
                            <a
                              href="https://www.naics.com/search/"
                              target="_blank"
                            >
                              <!-- <b-icon icon="info"  aria-hidden="true"></b-icon> -->
                              <img
                                style="width: 14px; margin-top: -4px;"
                                src="@/assets/images/question_mark.svg"
                              />
                            </a>
                          </label>

                          <ValidationProvider
                            name="NAICS Code"
                            rules="required|min:5|max:25"
                            v-slot="{ errors }"
                          >
                            <input
                              type="number"
                              @blur="autoSave"
                              name="NAICSCode"
                              v-model="Payload.NAICSCode"
                              class="form-control"
                            />
                            <span
                              class="v-messages error-msg"
                              v-if="errors[0]"
                              >{{ errors[0] }}</span
                            >
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <!--
                                             <div class="form-group" v-for="(method , indx) in acc_method"  :key="indx">
                                                    <label class="radio_label" >{{method.name}}
                                                        <input type="radio" @change="selectedAccount( method)" :value="method._id" v-model="Payload.accMethodId" :checked="method._id ==Payload.accMethodId"    name="radio">
                                                        <span class="checkmark"></span>
                                                    </label>

                                            </div>
                                            -->
                        <div class="form-group">
                          <label class="form_label">Accounting Method</label>
                          <ValidationProvider
                            :name="'Accounting Method'"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <multiselect
                              v-model="Payload.accMethodDetails"
                              :options="acc_method"
                              :searchable="true"
                              :multiple="false"
                              :close-on-select="true"
                              :allow-empty="false"
                              @input="selectedAccount"
                              label="name"
                              value="value1"
                              placeholder="Select Accounting Method"
                              track-by="name"
                              :name="'Accounting Method'"
                            >
                            </multiselect>

                            <span
                              class="v-messages error-msg"
                              v-if="errors[0]"
                              >{{
                                errors[0] ? "Accounting Method is required" : ""
                              }}</span
                            >
                          </ValidationProvider>
                        </div>
                        <!--<div class="form-group">
                                                <label class="radio_label">Accrual
                                                <input type="radio" name="radio">
                                                <span class="checkmark"></span>
                                                </label>
                                            </div>-->
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label class="form_label">Input Your Industry</label>
                          <ValidationProvider
                            name="Industry"
                            rules="required|max:100"
                            v-slot="{ errors }"
                          >
                            <input
                              type="text"
                              @blur="autoSave"
                              name="industryIds"
                              v-model="Payload.industryIds"
                              placeholder="i.e. Fitness Equipment, Food, Beverage, Appareal, Pet Supplies, etc."
                              class="form-control"
                            />
                            <span
                              class="v-messages error-msg"
                              v-if="errors[0]"
                              >{{ errors[0] }}</span
                            >
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="form_section white_bg">
                <form>
                  <h4 class="title marb0">
                    Business Type (You can select multiple, if applicable)
                  </h4>
                  <div class="form_sub_sec">
                    <!--<div class="pt-3 pb-4">
                                         <label class="form_subtitle mb-0">Is your business Wholesale, Retail or Both?</label>
                                         <ul>

                                            <li v-for="(method , indx) in bus_type"  :key="indx" >
                                                <div class="form-group">
                                                    <label class="radio_label" @click="selectedbType(method)">{{method.name}}
                                                    <input type="radio"  :value="method._id" v-model="Payload.busTypeId"  :checked="method._id==Payload.busTypeId"  name="busType">
                                                    <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="form-group">
                                                    <ValidationProvider :name="'busType'"   rules="required" v-slot="{ errors }">
                                                <input type="hidden" name="Business Type" v-model="Payload.busTypeId">
                                                <span  class="v-messages error-msg" v-if="errors[0]">{{ errors[0]?'Business Type is required':'' }} </span>

                                                </ValidationProvider>
                                                </div>
                                            </li>

                                        </ul>
                                        </div>-->
                    <div class="form-group pt-2">
                      <label class="form_subtitle mb-0--"
                        >Is your business Wholesale, Retail or Both?</label
                      >
                      <ValidationProvider
                        :name="'busType'"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <multiselect
                          v-model="Payload.businessTypeDetails"
                          :options="bus_type"
                          :searchable="true"
                          :multiple="true"
                          :close-on-select="true"
                          :allow-empty="true"
                          @input="selectedbType"
                          label="name"
                          value="value1"
                          placeholder="Select Business"
                          track-by="name"
                          :name="'busType'"
                        >
                          <template
                            slot="selection"
                            slot-scope="{ values, isOpen }"
                          >
                            <span
                              class="multiselect__selectcustom"
                              v-if="values.length && !isOpen"
                              >{{ values.length }} selected</span
                            >
                            <span
                              class="multiselect__selectcustom"
                              v-if="values.length && isOpen"
                            ></span>
                          </template>
                        </multiselect>
                        <div
                          v-if="
                            Payload.businessTypeDetails &&
                              Payload.businessTypeDetails.length
                          "
                          style="font-size: 11px;"
                        >
                          Selected:
                          <span>{{
                            Payload.businessTypeDetails
                              .map((option) => option.name)
                              .join(", ")
                          }}</span>
                        </div>

                        <span class="v-messages error-msg" v-if="errors[0]">{{
                          errors[0] ? "Business Type is required" : ""
                        }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="sub_list">
                      <label class="form_subtitle"
                        >What tax calculator do you use? TaxJar, Avalara,
                        Shopify or Custom API (please specify)</label
                      >

                      <!--
                                        <ul class="d-flex-">
                                            <li>
                                                <div class="form-group">
                                                    <label class="radio_label">YES
                                                    <input type="radio" :value="true" name="taxjar" v-model="Payload.doYouUserTaxzarApi" >
                                                    <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="form-group">
                                                    <label class="radio_label">NO
                                                    <input type="radio" :value="false" name="taxjar"  v-model="Payload.doYouUserTaxzarApi" >
                                                    <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                        {{v-if="Payload.doYouUserTaxzarApi"}}
                                        -->

                      <div class="pad0--- pt-4---">
                        <div class="form-group">
                          <!-- <label class="form_label">Description</label> -->
                          <ValidationProvider
                            name="Description"
                            rules="required|max:50"
                            v-slot="{ errors }"
                          >
                            <input
                              placeholder="TaxJar or Custom API"
                              :name="' taxJarDescription'"
                              v-model="Payload.taxJarDescription"
                              type="text"
                              @blur="autoSave"
                              class="form-control"
                            />
                            <span
                              class="v-messages error-msg"
                              v-if="errors[0]"
                              >{{ errors[0] }}</span
                            >
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                    <div class="sub_list physical_store">
                      <label class="form_subtitle">Physical Store</label>
                      <ul class="d-flex--">
                        <li>
                          <div class="form-group">
                            <label class="radio_label"
                              >YES
                              <input
                                type="radio"
                                :value="true"
                                name="store"
                                v-model="Payload.havePhysicalStore"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div class="form-group">
                            <label class="radio_label"
                              >NO
                              <input
                                type="radio"
                                :value="false"
                                name="store"
                                v-model="Payload.havePhysicalStore"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </li>
                      </ul>

                      <div class="mt-0  pt-0" v-if="Payload.havePhysicalStore">
                        <addressComponent
                          v-bind:autoSave="autoSave"
                          v-bind:address="Payload.physicalStoreAddress"
                          v-bind:addressType="'physicalStoreAddress'"
                        />
                      </div>
                    </div>

                    <div class="sub_list">
                      <div class="form-group" style="display: none">
                        <label class="form_label"
                          >List of selling platforms</label
                        >

                        <ValidationProvider
                          name="Selling Platforms"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <multiselect
                            v-model="Payload.platformDetails"
                            :value="'_id'"
                            :options="platform"
                            :searchable="true"
                            :close-on-select="true"
                            :allow-empty="true"
                            :multiple="true"
                            label="name"
                            placeholder="Select Selling Platforms"
                            track-by="name"
                            @input="updateSellingPlatforms"
                            id="platform"
                          >
                            <template
                              slot="selection"
                              slot-scope="{ values, isOpen }"
                            >
                              <span
                                class="multiselect__selectcustom"
                                v-if="values.length && !isOpen"
                                >{{ values.length }} Selling Platform(s)
                                selected</span
                              >
                              <span
                                class="multiselect__selectcustom"
                                v-if="values.length && isOpen"
                              ></span>
                            </template>
                          </multiselect>

                          <span class="v-messages error-msg" v-if="errors[0]">{{
                            errors[0]
                          }}</span>
                        </ValidationProvider>
                      </div>
                      <!--<div class="form-group mart30" v-if="checkShopyfy">
                        <label class="form_label">Store Address/URL</label>

                        <ValidationProvider
                          name="Store Address/URL"
                          rules="required|urlvalidation"
                          v-slot="{ errors }"
                        >
                          <input
                            v-model="Payload.shopifyUrlOrAddress"
                            class="form-control"
                          />
                          <span class="v-messages error-msg" v-if="errors[0]">{{
                            errors[0]
                          }}</span>
                        </ValidationProvider>
                      </div>-->

                      <template>
                        <div class="form-group mart30">
                          <!-- Platform selection -->
                          <label class="form_label"
                            >What platform is the shared email address hosted
                            on? *</label
                          >

                          <ValidationProvider
                            name="Email Host"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <BaseRadioButtonGroup
                              :options="email_host"
                              v-model="Payload.emailHost"
                              @input="updateEmailHost"
                              :selectedValue="Payload.emailHost"
                              name="email_host"
                            />

                            <input
                              v-if="Payload.emailHost === 3"
                              v-model="Payload.customPlatformHosted"
                              placeholder="Enter other platform"
                              type="text"
                              class="form-control other-input"
                            />

                            <span
                              class="v-messages error-msg"
                              v-if="errors[0]"
                              >{{ errors[0] }}</span
                            >
                          </ValidationProvider>

                          <!-- Email input field -->
                          <label class="form_label mart30"
                            >What is the email? *</label
                          >
                          <ValidationProvider
                            name="Email Address"
                            rules="required|email"
                            v-slot="{ errors }"
                          >
                            <input
                              v-model="Payload.emailPlatformHosted"
                              type="email"
                              placeholder="Enter a valid email"
                              id="email"
                              class="form-control"
                            />
                            <span
                              class="v-messages error-msg"
                              v-if="errors[0]"
                              >{{ errors[0] }}</span
                            >
                          </ValidationProvider>

                          <!-- Password input field (no validation) -->
                          <label class="form_label mart30"
                            >What is the Password? *</label
                          >
                          <div class="password-field">
                            <input
                              v-model="Payload.passwordPlatformHosted"
                              :type="showPassword ? 'text' : 'password'"
                              placeholder="Enter password"
                              id="password"
                              class="form-control"
                            />
                            <button
                              type="button"
                              @click="togglePasswordVisibility"
                              class="toggle-password-btn"
                            >
                              {{
                                showPassword ? "Hide Password" : "Show Password"
                              }}
                            </button>
                          </div>

                          <!-- Confirmation checkbox -->
                          <label class="form_label mart30"
                            >Please confirm you have changed all state sales tax
                            logins to the new email address *</label
                          >

                          <ValidationProvider
                            name="Confirmation"
                            rules="checked"
                            v-slot="{ errors }"
                          >
                            <div class="form-check">
                              <input
                                v-model="Payload.confirmedPlatformHosted"
                                type="checkbox"
                                id="confirmed"
                                class="form-check-input"
                              />
                              <label class="form-check-label" for="confirmed"
                                >Confirmed</label
                              >
                            </div>
                            <span
                              class="v-messages error-msg"
                              v-if="errors.length"
                              >{{ errors[0] }}</span
                            >
                          </ValidationProvider>
                        </div>
                      </template>

                      <!--<div class="form-group mart30">
                        <label class="form_label"
                          >What platform is this email hosted on?</label
                        >

                        <ValidationProvider
                          name="Email Host"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <multiselect
                            v-model="Payload.hostDetails"
                            :value="'_id'"
                            :options="email_host"
                            :searchable="true"
                            :close-on-select="true"
                            :allow-empty="true"
                            :multiple="false"
                            label="name"
                            placeholder="Select Email Host"
                            track-by="name"
                            id="platform"
                            @input="updateEmailHost"
                          >
                            <template
                              slot="selection"
                              slot-scope="{ values, isOpen }"
                            >
                              <span
                                class="multiselect__selectcustom"
                                v-if="values.length && !isOpen"
                                >{{ values.length }} Email Host(s)
                                selected</span
                              >
                              <span
                                class="multiselect__selectcustom"
                                v-if="values.length && isOpen"
                              ></span>
                            </template>
                          </multiselect>

                          <span class="v-messages error-msg" v-if="errors[0]">{{
                            errors[0]
                          }}</span>
                        </ValidationProvider>
                      </div>-->
                    </div>

                    <div class="sub_list">
                      <!--<div class="idenfiers_list">
                        <div class="sub_list_cnt">
                          <div class="form-group form-group-full pdl_5">
                            <label class="form_label">Unique ID</label>
                            <input
                              type="hidden"
                              v-model="Payload.identifiers[0].marketPlace"
                            />
                            <ValidationProvider
                              :name="'Unique ID'"
                              rules="required|min:5|max:25"
                              v-slot="{ errors }"
                            >
                              <input
                                type="text"
                                @blur="autoSave"
                                :name="'identifier0'"
                                v-model="Payload.identifiers[0].identifier"
                                class="form-control"
                              />
                              <span
                                class="v-messages error-msg trrr"
                                v-if="errors[0] && errors[0] != ''"
                              >
                                {{ errors[0] }}
                              </span>
                            </ValidationProvider>
                          </div>
                        </div>
                        <div class="sub_list_cnt">
                          <div
                            class="form-group pdr_5 view_password_wrap view_password_wrap2"
                          >
                            <div class="view_password">
                              <label class="form_label">Client Key</label>
                              <ValidationProvider
                                :name="'Client Key0'"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <input
                                  v-model="Payload.identifiers[0].clientKey"
                                  :type="Payload.identifiers[0].clientKeyType"
                                  class="form-control"
                                />
                                <span
                                  class="v-messages error-msg"
                                  v-if="errors[0]"
                                >
                                  {{
                                    errors[0] ? "Client Key is required" : ""
                                  }}
                                </span>
                              </ValidationProvider>
                            </div>

                            <template
                              v-if="
                                Payload.identifiers[0].clientKeyType ==
                                  'password'
                              "
                            >
                              <div
                                class="eye_icon"
                                title="show"
                                @click="toggleTextType(0, 'clientKeyType')"
                              >
                                <b-icon
                                  icon="eye-slash-fill"
                                  aria-hidden="true"
                                ></b-icon>
                              </div>
                            </template>
                            <template v-else>
                              <div
                                class="eye_icon"
                                title="hide"
                                @click="toggleTextType(0, 'clientKeyType')"
                              >
                                <b-icon
                                  icon="eye-fill"
                                  aria-hidden="true"
                                ></b-icon>
                              </div>
                            </template>
                          </div>
                          <div
                            class="form-group pdl_5 view_password_wrap view_password_wrap2"
                          >
                            <div class="form-group view_password">
                              <label class="form_label">Client Secret</label>
                              <ValidationProvider
                                :name="'Client Secret0'"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <input
                                  v-model="Payload.identifiers[0].clientSecret"
                                  :type="
                                    Payload.identifiers[0].clientSecretType
                                  "
                                  class="form-control"
                                />
                                <span
                                  class="v-messages error-msg"
                                  v-if="errors[0]"
                                >
                                  {{
                                    errors[0] ? "Client Secret is required" : ""
                                  }}
                                </span>
                              </ValidationProvider>
                            </div>
                            <template
                              v-if="
                                Payload.identifiers[0].clientSecretType ==
                                  'password'
                              "
                            >
                              <div
                                class="eye_icon"
                                title="show"
                                @click="toggleTextType(0, 'clientSecretType')"
                              >
                                <b-icon
                                  icon="eye-slash-fill"
                                  aria-hidden="true"
                                ></b-icon>
                              </div>
                            </template>
                            <template v-else>
                              <div
                                class="eye_icon"
                                title="hide"
                                @click="toggleTextType(0, 'clientSecretType')"
                              >
                                <b-icon
                                  icon="eye-fill"
                                  aria-hidden="true"
                                ></b-icon>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>-->

                      <h4 class="title title--normalcase">
                        Shopify Store Access
                      </h4>
                      <label class="form_subtitle"
                        ><span class="css-space-right">More info on this here</span><a
                          href="https://help.shopify.com/en/manual/your-account/staff-accounts/collaborator-accounts"
                          target="_blank"
                          >https://help.shopify.com/en/manual/your-account/staff-accounts/collaborator-accounts</a
                        ></label
                      >
                      <div class="idenfiers_list">
                        <div class="sub_list_cnt">
                          <div class="form-group form-group-full pdl_5">
                            <label class="form_label">Shopify Link</label>
                            <ValidationProvider
                              :name="'Shopify Link'"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <input
                                type="text"
                                @blur="autoSave"
                                v-model="Payload.identifiers[0].shopifyLink"
                                class="form-control"
                              />
                              <span
                                class="v-messages error-msg"
                                v-if="errors[0]"
                              >
                                {{ errors[0] }}
                              </span>
                            </ValidationProvider>
                          </div>
                        </div>
                        <div class="sub_list_cnt">
                          <div class="form-group form-group-full pdl_5">
                            <label class="form_label">Collaborator Code</label>
                            <ValidationProvider
                              :name="'Collaborator Code'"
                              rules="max:25"
                              v-slot="{ errors }"
                            >
                              <input
                                type="text"
                                @blur="autoSave"
                                v-model="
                                  Payload.identifiers[0].collaboratorCode
                                "
                                class="form-control"
                              />
                              <span
                                class="v-messages error-msg"
                                v-if="errors[0]"
                              >
                                {{ errors[0] }}
                              </span>
                            </ValidationProvider>
                          </div>
                        </div>
                      </div>
                      <br />
                      <label class="form_subtitle"
                        >How to Approve Shopify Collaborator Access</label
                      >
                      <label class="form_subtitle">
                        <a
                          href="http://youtube.com/watch?v=yATTpXmXmBI"
                          target="_blank"
                          >http://youtube.com/watch?v=yATTpXmXmBI</a
                        >
                      </label>
                    </div>
                    <div class="sub_list">
                      <label class="form_subtitle"
                        >Are you a marketplace facilitator?
                      </label>
                      <ul>
                        <li>
                          <div class="form-group">
                            <label class="radio_label"
                              >YES
                              <input
                                type="radio"
                                :value="true"
                                v-model="Payload.marketPlaceFecilitator"
                                name="Facilitator"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div class="form-group">
                            <label class="radio_label"
                              >NO
                              <input
                                type="radio"
                                :value="false"
                                v-model="Payload.marketPlaceFecilitator"
                                name="Facilitator"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div class="sub_list">
                      <label class="form_subtitle"
                        >Has the business been involved in a merger within the
                        last seven years?
                      </label>
                      <ul>
                        <li>
                          <div class="form-group">
                            <label class="radio_label"
                              >YES
                              <input
                                type="radio"
                                :value="true"
                                v-model="Payload.involvedInMerge"
                                name="merger"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div class="form-group">
                            <label class="radio_label"
                              >NO
                              <input
                                type="radio"
                                :value="false"
                                v-model="Payload.involvedInMerge"
                                name="merger"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div class="sub_list">
                      <label class="form_subtitle"
                        >Do you sell on a marketplace?</label
                      >
                      <ul>
                        <li>
                          <div class="form-group">
                            <label class="radio_label"
                              >YES
                              <input
                                type="radio"
                                :value="true"
                                v-model="Payload.sellOnMarketPlace"
                                name="marketpalce"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div class="form-group">
                            <label class="radio_label"
                              >NO
                              <input
                                type="radio"
                                :value="false"
                                v-model="Payload.sellOnMarketPlace"
                                name="marketpalce"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </li>
                      </ul>
                      <div
                        class="form-group mart30"
                        v-if="Payload.sellOnMarketPlace"
                      >
                        <label class="form_label"
                          >Please indicate what marketplace</label
                        >

                        <!-- <ValidationProvider name="Market Place" rules="required" v-slot="{ errors }">
                                                                <input v-model="Payload.marketPlaceSellId" class="form-control">
                                                                <span  class="v-messages error-msg" v-if="errors[0]">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                        -->
                        <ValidationProvider
                          name="Marketplace"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <!--<multiselect
                            v-model="Payload.marketPlaceSellDetails"
                            :value="'_id'"
                            :options="market_place_type"
                            :searchable="true"
                            :close-on-select="true"
                            :allow-empty="true"
                            :multiple="false"
                            @input="updatemarketPlaceSell"
                            label="name"
                            placeholder="Select Marketplace"
                            track-by="name"
                            :hide-selected="false"
                          >
                            <template
                              slot="selection"
                              slot-scope="{ values, isOpen }"
                            >
                              <span
                                class="multiselect__selectcustom"
                                v-if="values.length && !isOpen"
                                >{{ values.length }} Market Place (s)
                                selected</span
                              >
                              <span
                                class="multiselect__selectcustom"
                                v-if="values.length && isOpen"
                              ></span>
                            </template>
                          </multiselect>-->
                          <div class="checkbox-group">
                            <label
                              v-for="option in market_place_type"
                              :key="option._id"
                              class="checkbox-label"
                            >
                              <input
                                type="checkbox"
                                :value="option._id"
                                v-model="Payload.marketPlaceSellId"
                                @change="handleCheckboxChange()"
                                :checked="
                                  Payload.marketPlaceSellId.includes(option._id)
                                "
                              />
                              {{ option.name }}
                            </label>
                            <div v-if="Payload.marketPlaceSellId.includes(6)">
                              <input
                                type="text"
                                v-model="Payload.otherMarketPlace"
                                placeholder="Enter marketplace"
                                class="form-control form-control-inline"
                              />
                            </div>
                          </div>

                          <span class="v-messages error-msg" v-if="errors[0]">{{
                            errors[0]
                          }}</span>
                        </ValidationProvider>
                      </div>
                    </div>

                    <div class="sub_list">
                      <label class="form_subtitle"
                        >Has the business been notified of any changes to
                        previous returns by any taxing authority? If yes, please
                        indicate which state and reason.</label
                      >
                      <ul>
                        <li>
                          <div class="form-group">
                            <label class="radio_label"
                              >YES
                              <input
                                type="radio"
                                :value="true"
                                v-model="Payload.previousReturnNotifyChanges"
                                name="previousReturnNotifyChanges"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div class="form-group">
                            <label class="radio_label"
                              >NO
                              <input
                                type="radio"
                                :value="false"
                                v-model="Payload.previousReturnNotifyChanges"
                                name="previousReturnNotifyChanges"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </li>
                      </ul>

                      <div
                        v-if="Payload.previousReturnNotifyChanges"
                        class="sub_list-- "
                      >
                        <template
                          v-for="(item,
                          index) in Payload.previousReturnNotifyStates"
                        >
                          <div class="idenfiers_list" :key="index">
                            <div class="sub_list_cnt">
                              <div class="form-group pdr_5">
                                <label class="form_label">Reason</label>
                                <ValidationProvider
                                  :name="'Reason' + index"
                                  rules="required"
                                  v-slot="{ errors }"
                                >
                                  <input
                                    v-model="item.reason"
                                    type="text"
                                    @blur="autoSave"
                                    class="form-control"
                                  />
                                  <span
                                    class="v-messages error-msg"
                                    v-if="errors[0]"
                                  >
                                    Reason is required</span
                                  >
                                </ValidationProvider>
                              </div>

                              <div class="form-group pdl_5">
                                <label class="form_label">State</label>
                                <!-----usstateList-->
                                <ValidationProvider
                                  :name="'States' + index"
                                  rules="required"
                                  v-slot="{ errors }"
                                >
                                  <multiselect
                                    v-model="item.stateDetails"
                                    :value="'_id'"
                                    :options="filteredUsstateList"
                                    :searchable="true"
                                    :close-on-select="true"
                                    :allow-empty="true"
                                    :multiple="false"
                                    @input="findUsedStates"
                                    label="name"
                                    placeholder="Select states"
                                    track-by="name"
                                    :hide-selected="true"
                                  >
                                    <template
                                      slot="selection"
                                      slot-scope="{ values, isOpen }"
                                    >
                                      <span
                                        class="multiselect__selectcustom"
                                        v-if="values.length && !isOpen"
                                        >{{ values.length }} States (s)
                                        selected</span
                                      >
                                      <span
                                        class="multiselect__selectcustom"
                                        v-if="values.length && isOpen"
                                      ></span>
                                    </template>
                                  </multiselect>

                                  <span
                                    class="v-messages error-msg"
                                    v-if="errors[0]"
                                    >State is required</span
                                  >
                                </ValidationProvider>
                              </div>
                            </div>

                            <div class="sub_list_cnt">
                              <div
                                class="delete_btn delete_btn2"
                                v-if="
                                  Payload.previousReturnNotifyStates.length > 1
                                "
                                @click="removePreviousReturnNotifyState(index)"
                              >
                                <b-icon
                                  icon="trash"
                                  aria-hidden="true"
                                ></b-icon>
                              </div>
                            </div>
                          </div>
                        </template>
                        <span
                          class="primary_btn add_more mart15 marb15"
                          @click="addPreviousReturnNotifyState()"
                          >+More</span
                        >
                      </div>
                    </div>

                    <div class="sub_list">
                      <label class="form_subtitle">
                        Did you purchase an existing business?
                        <!--<p><i></i>If so, we may need additional information.</p>-->
                      </label>

                      <ul>
                        <li>
                          <div class="form-group">
                            <label class="radio_label"
                              >YES
                              <input
                                type="radio"
                                @change="isPurchaseExistingBusiness"
                                :value="true"
                                v-model="Payload.purchaseExistingBusiness"
                                name="purchaseExistingBusiness"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div class="form-group">
                            <label class="radio_label"
                              >NO
                              <input
                                type="radio"
                                name="purchaseExistingBusiness"
                                @change="isPurchaseExistingBusiness"
                                :value="false"
                                v-model="Payload.purchaseExistingBusiness"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </li>
                      </ul>

                      <div
                        class="form-group mart30"
                        v-if="Payload.purchaseExistingBusiness"
                      >
                        <label class="form_label"
                          >Purchase Agreement Documents</label
                        >
                        <div class="upload_sec">
                          <uploadFile
                            @onUploadComplete="onUploadComplete"
                            :allowMultiple="true"
                            :acceptedFiles="'*'"
                            :indexValue="-1"
                            :takeIndex="false"
                            :payLoadKey="'purchaseAgreementDocs'"
                            :payLoadDocumentsKey="''"
                          />
                          <ValidationProvider
                            :name="'PurchaseAgreementDocs'"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <input
                              type="hidden"
                              name="PurchaseAgreementDocs"
                              v-model="purchaseAgreementDocs"
                            />
                            <span class="v-messages error-msg" v-if="errors[0]"
                              >{{
                                errors[0]
                                  ? "Purchase Agreement Documents is required"
                                  : ""
                              }}
                            </span>
                          </ValidationProvider>
                        </div>
                        <ul class="uploaded-list">
                          <template
                            v-for="(other,
                            indx) in Payload.purchaseAgreementDocs"
                          >
                            <li :key="indx">
                              {{ other.name }}
                              <span
                                @click="
                                  removeDoc(
                                    indx,
                                    Payload.purchaseAgreementDocs,
                                    'purchaseAgreementDocs'
                                  )
                                "
                                >X</span
                              >
                            </li>
                          </template>
                        </ul>
                      </div>
                    </div>
                    <div class="sub_list">
                      <label class="form_subtitle"
                        >Is your company a subsidary of a parent company?</label
                      >
                      <ul class="padt20">
                        <li>
                          <div class="form-group">
                            <label class="radio_label"
                              >YES
                              <input
                                type="radio"
                                :value="true"
                                v-model="Payload.isSubsidaryOfParentCompany"
                                name="isSubsidaryOfParentCompany"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div class="form-group">
                            <label class="radio_label"
                              >NO
                              <input
                                type="radio"
                                :value="false"
                                v-model="Payload.isSubsidaryOfParentCompany"
                                name="isSubsidaryOfParentCompany"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </li>
                      </ul>
                      <div class="sub_list">
                        <div
                          class="form-group marb30"
                          v-if="Payload.isSubsidaryOfParentCompany"
                        >
                          <label class="form_label">Company Name</label>
                          <ValidationProvider
                            name="Company Name"
                            rules="required|max:100"
                            v-slot="{ errors }"
                          >
                            <input
                              v-model="Payload.parentCompanyName"
                              class="form-control"
                            />
                            <span
                              class="v-messages error-msg"
                              v-if="errors[0]"
                              >{{ errors[0] }}</span
                            >
                          </ValidationProvider>
                        </div>
                        <div class="form-group">
                          <label class="form_label"
                            >Upload Cap table if the business has multiple
                            owners/shareholders/entities
                            <a
                              href="https://www.investopedia.com/terms/c/capitalization-table.asp"
                              target="_blank"
                            >
                              <!-- <b-icon icon="info"  aria-hidden="true"></b-icon> -->
                              <img
                                style="width: 14px; margin-top: -4px;"
                                src="@/assets/images/question_mark.svg"
                              />
                            </a>
                          </label>

                          <div class="upload_sec">
                            <uploadFile
                              @onUploadComplete="onUploadComplete"
                              :allowMultiple="true"
                              :acceptedFiles="'*'"
                              :indexValue="-1"
                              :takeIndex="false"
                              :payLoadKey="'capTableDocs'"
                              :payLoadDocumentsKey="''"
                            />
                            <ValidationProvider
                              :name="'CapDocs'"
                              v-slot="{ errors }"
                            >
                              <input
                                type="hidden"
                                name="CapDocs"
                                v-model="capTableDocs"
                              />
                              <span
                                class="v-messages error-msg"
                                v-if="errors[0]"
                                >{{
                                  errors[0]
                                    ? "Cap Table Document is required"
                                    : ""
                                }}
                              </span>
                            </ValidationProvider>
                          </div>
                          <ul class="uploaded-list">
                            <template
                              v-for="(other, indx) in Payload.capTableDocs"
                            >
                              <li :key="indx">
                                {{ other.name }}
                                <span
                                  @click="removeDoc(indx, Payload.capTableDocs)"
                                  >X</span
                                >
                              </li>
                            </template>
                          </ul>
                        </div>
                      </div>
                      <!-- <div class="sub_list">
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <h6 class="form_label">
                                How many owners own more than 20%?
                              </h6>
                              <ValidationProvider
                                name="How many owners own more than 20%?"
                                v-slot="{ errors }"
                              >
                                <input
                                  type="number"
                                  min="0"
                                  oninput="this.value = this.value.replace(/[^0-9 .]/g, '').replace(/(\..*)\./g, '$1'); this.value = this.value.replace(/ /g,'');"
                                  name="ownersMorethan20Percent"
                                  v-model="Payload.ownersMorethan20Percent"
                                  class="form-control"
                                />
                                <span
                                  class="v-messages error-msg"
                                  v-if="errors[0]"
                                  >{{ errors[0] }}</span
                                >
                              </ValidationProvider>
                            </div>
                          </div>
                        </div>
                      </div> -->
                    </div>
                    <div class="sub_list">
                      <h4 class="title">Employees</h4>
                      <label class="form_subtitle mb-0"
                        >Please list all states where employees or agents (1099)
                        are performing sales or doing any business activities.
                        If employees are remote, their home state should be
                        entered.</label
                      >
                      <ul class="padt20">
                        <li>
                          <div class="form-group">
                            <label class="radio_label"
                              >YES
                              <input
                                type="radio"
                                :value="true"
                                v-model="Payload.haveEmployeesOrAgents"
                                name="haveEmployeesOrAgents"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div class="form-group">
                            <label class="radio_label"
                              >NO
                              <input
                                type="radio"
                                :value="false"
                                v-model="Payload.haveEmployeesOrAgents"
                                name="haveEmployeesOrAgents"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </li>
                      </ul>
                      <div
                        class="form-group mart30"
                        v-if="Payload.haveEmployeesOrAgents"
                      >
                        <label class="form_label">States</label>

                        <ValidationProvider
                          name="States"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <multiselect
                            v-model="Payload.otherBusStates"
                            :value="'_id'"
                            :options="usstateList"
                            :searchable="true"
                            :close-on-select="false"
                            :allow-empty="true"
                            :multiple="true"
                            @input="updateotherBusState"
                            label="name"
                            placeholder="Select states"
                            track-by="name"
                            :hide-selected="true"
                          >
                            <template
                              slot="selection"
                              slot-scope="{ values, isOpen }"
                            >
                              <span
                                class="multiselect__selectcustom"
                                v-if="values.length && !isOpen"
                                >{{ values.length }} States (s) selected</span
                              >
                              <span
                                class="multiselect__selectcustom"
                                v-if="values.length && isOpen"
                              ></span>
                            </template>
                          </multiselect>

                          Selected:
                          <span>{{
                            Payload.otherBusStates
                              .map((option) => option.name)
                              .join(", ")
                          }}</span>

                          <span class="v-messages error-msg" v-if="errors[0]">{{
                            errors[0]
                          }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="sub_list">
                      <div class="form-group mart30---">
                        <label class="form_label"
                          >Indicate the states where you have employees and run
                          payroll for (W-2).</label
                        >

                        <ValidationProvider name="States" v-slot="{ errors }">
                          <multiselect
                            v-model="Payload.empPayrollStateDetails"
                            :value="'_id'"
                            :options="usstateList"
                            :searchable="true"
                            :close-on-select="false"
                            :allow-empty="true"
                            :multiple="true"
                            @input="updateEmpPayrollStates"
                            label="name"
                            placeholder="Select states"
                            track-by="name"
                            :hide-selected="true"
                          >
                            <template
                              slot="selection"
                              slot-scope="{ values, isOpen }"
                            >
                              <span
                                class="multiselect__selectcustom"
                                v-if="values.length && !isOpen"
                                >{{ values.length }} States (s) selected</span
                              >
                              <span
                                class="multiselect__selectcustom"
                                v-if="values.length && isOpen"
                              ></span>
                            </template>
                          </multiselect>

                          <span class="v-messages error-msg" v-if="errors[0]">{{
                            errors[0]
                          }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="sub_list">
                      <div class="form-group">
                        <label class="form_label"
                          >Payroll Tax ID Number/Unemployment Insurance (UI) Tax
                          Number (Ex. CA – 123456, TX - 987654</label
                        >
                        <ValidationProvider
                          :name="'Payroll Tax ID Number'"
                          v-slot="{ errors }"
                        >
                          <input
                            type="text"
                            @blur="autoSave"
                            v-model="Payload.taxNumber"
                            class="form-control"
                          />
                          <span class="v-messages error-msg" v-if="errors[0]"
                            >{{ errors[0] }}
                          </span>
                        </ValidationProvider>
                      </div>
                    </div>
                    <!--<div class="sub_list">
                      <div class="form-group">
                        <label class="form_label">Corporate Website</label>
                        <ValidationProvider
                          :name="'Corporate website'"
                          rules="required|urlvalidation"
                          v-slot="{ errors }"
                        >
                          <input
                            type="text"
                            @blur="autoSave"
                            v-model="Payload.website"
                            class="form-control"
                          />
                          <span class="v-messages error-msg" v-if="errors[0]"
                            >{{ errors[0] }}
                          </span>
                        </ValidationProvider>
                      </div>
                    </div>-->
                    <!--
                                        <div class="sub_list">
                                        <label class="form_subtitle">Did you have a paid sales tax consultation with cherry bekeat?</label>
                                        <ul>
                                            <li>
                                                <div class="form-group">
                                                    <label class="radio_label">YES
                                                    <input type="radio" checked="checked" name="bekeat">
                                                    <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="form-group">
                                                    <label class="radio_label">NO
                                                    <input type="radio" name="bekeat">
                                                    <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                        </div>
                                        <div class="sub_list">
                                        <label class="form_subtitle">Do you use taxjar</label>
                                        <ul>
                                            <li>
                                                <div class="form-group">
                                                    <label class="radio_label">taxjar
                                                    <input type="radio" checked="checked" name="states">
                                                    <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="form-group">
                                                    <label class="radio_label">taxjar
                                                    <input type="radio" name="states">
                                                    <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                        </div> -->
                  </div>
                  <!-- <span class="devider"></span>
                                        <span class="devider"></span> -->
                  <!--
                                        <span class="devider"></span>
                                        <div class="row">
                                            <div class="col">
                                                <label class="form_subtitle">Are You A Marketplace Facilitator</label>
                                                <div class="d-flex">


                                                </div>
                                            </div>
                                        </div> -->
                  <!-- <span class="devider"></span>
                                        <div class="row">
                                            <div class="col">
                                                <label class="form_subtitle"></label>
                                                <div class="d-flex">
                                                    <div class="form-group">
                                                        <label class="radio_label">YES
                                                            <input type="radio" checked="checked" name="marketpalce">
                                                            <span class="checkmark"></span>
                                                        </label>
                                                    </div>
                                                    <div class="form-group">
                                                        <label class="radio_label">NO
                                                            <input type="radio" name="marketpalce">
                                                            <span class="checkmark"></span>
                                                            </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                </form>
              </div>
              <div class="form_section">
                <form>
                  <div>
                    <h4 class="title">Bank Details</h4>

                    <label class="form_subtitle"
                      >Please state the bank account information below for
                      paying sales tax owed and permits to states</label
                    >
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <ValidationProvider
                            name="Bank"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <multiselect
                              v-model="Payload.bankDetails"
                              :value="'_id'"
                              :options="bank"
                              :searchable="true"
                              :close-on-select="false"
                              :allow-empty="true"
                              :multiple="true"
                              label="name"
                              placeholder="Select Banks"
                              track-by="name"
                              @input="addBank"
                              :hide-selected="true"
                            >
                              <template
                                slot="selection"
                                slot-scope="{ values, isOpen }"
                              >
                                <span
                                  class="multiselect__selectcustom"
                                  v-if="values.length && !isOpen"
                                  >{{ values.length }} Bank (s) selected</span
                                >
                                <span
                                  class="multiselect__selectcustom"
                                  v-if="values.length && isOpen"
                                ></span>
                              </template>
                            </multiselect>

                            Selected:
                            <span>{{
                              Payload.bankDetails
                                .map((option) => option.name)
                                .join(", ")
                            }}</span>

                            <span
                              class="v-messages error-msg"
                              v-if="errors[0]"
                              >{{ errors[0] }}</span
                            >
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        v-for="(bnk, indx) in Payload.banks"
                        :key="indx"
                        class="bank_details_list"
                      >
                        <div
                          v-if="Payload.banks.length > 1"
                          class="delete_btn"
                          @click="removeBank(indx)"
                        >
                          <b-icon icon="trash" aria-hidden="true"></b-icon>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <label class="form_label">Bank Name </label>
                              <ValidationProvider
                                :name="'Bank Name' + indx"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <input
                                  v-show="
                                    Payload.bankDetails[indx] &&
                                      Payload.bankDetails[indx].name !==
                                        'Others'
                                  "
                                  type="text"
                                  @blur="autoSave"
                                  :disabled="
                                    Payload.bankDetails[indx] &&
                                      Payload.bankDetails[indx].name !==
                                        'Others'
                                  "
                                  v-model="bnk.name"
                                  class="form-control"
                                />
                                <input
                                  v-if="
                                    Payload.bankDetails[indx] &&
                                      Payload.bankDetails[indx].name ===
                                        'Others'
                                  "
                                  type="text"
                                  v-model="bnk.otherName"
                                  class="form-control"
                                />
                                <span
                                  class="v-messages error-msg"
                                  v-if="errors[0]"
                                  >{{
                                    errors[0] ? "Bank Name is required" : ""
                                  }}
                                </span>
                              </ValidationProvider>
                            </div>
                          </div>
                          <div class="col">
                            <div class="form-group">
                              <label class="form_label">Account Type </label>
                              <ValidationProvider
                                name="Acccounts"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <multiselect
                                  v-model="bnk.accType"
                                  :options="accTypes"
                                  :searchable="true"
                                  :close-on-select="true"
                                  :allow-empty="true"
                                  :multiple="false"
                                  placeholder="Select Account Type"
                                  :hide-selected="false"
                                >
                                  <template
                                    slot="selection"
                                    slot-scope="{ values, isOpen }"
                                  >
                                    <span
                                      class="multiselect__selectcustom"
                                      v-if="values.length && !isOpen"
                                      >{{ values.length }} Type (s)
                                      selected</span
                                    >
                                    <span
                                      class="multiselect__selectcustom"
                                      v-if="values.length && isOpen"
                                    ></span>
                                  </template>
                                </multiselect>

                                <span
                                  class="v-messages error-msg"
                                  v-if="errors[0]"
                                  >{{ errors[0] }}</span
                                >
                              </ValidationProvider>
                            </div>
                          </div>
                          <div class="col">
                            <div class="form-group date_desable">
                              <label class="form_label"
                                >Bank Phone Number</label
                              >
                              <ValidationProvider
                                name="Bank Phone Number"
                                rules="required|phonenumber"
                                v-slot="{ errors }"
                              >
                                <VuePhoneNumberInput
                                  default-country-code="US"
                                  :no-example="true"
                                  :only-countries="['US']"
                                  :no-country-selector="true"
                                  @update="updatePhoneNumber"
                                  :required="true"
                                  v-model="bnk.phone"
                                />
                                <span
                                  class="v-messages error-msg"
                                  v-if="errors[0]"
                                  >{{ errors[0] }}</span
                                >
                              </ValidationProvider>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <label class="form_label">Account Name</label>
                              <ValidationProvider
                                :name="'Account Name'"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <input
                                  type="text"
                                  @blur="autoSave"
                                  v-model="bnk.accountName"
                                  class="form-control"
                                />
                                <span
                                  class="v-messages error-msg"
                                  v-if="errors[0]"
                                  >{{ errors[0] }}
                                </span>
                              </ValidationProvider>
                            </div>
                          </div>
                          <div class="col">
                            <div class="form-group">
                              <label class="form_label">Account Number</label>
                              <ValidationProvider
                                :name="'Account number'"
                                rules="required|Alphanumeric|max:15"
                                v-slot="{ errors }"
                              >
                                <input
                                  type="text"
                                  @blur="autoSave"
                                  v-model="bnk.accNo"
                                  class="form-control"
                                />
                                <span
                                  class="v-messages error-msg"
                                  v-if="errors[0]"
                                  >{{ errors[0] }}
                                </span>
                              </ValidationProvider>
                            </div>
                          </div>

                          <div class="col">
                            <div class="form-group">
                              <label class="form_label">Routing Number</label>
                              <ValidationProvider
                                :name="'Routing Number'"
                                rules="required|onlyNumbers|min:9|max:9"
                                v-slot="{ errors }"
                              >
                                <input
                                  type="text"
                                  @blur="autoSave"
                                  v-model="bnk.routingNo"
                                  class="form-control"
                                />
                                <span
                                  class="v-messages error-msg"
                                  v-if="errors[0]"
                                  >{{ errors[0] }}
                                </span>
                              </ValidationProvider>
                            </div>
                          </div>
                        </div>
                        <addressComponent
                          v-bind:autoSave="autoSave"
                          v-bind:address="bnk.address"
                          v-bind:addressType="'bank' + indx"
                        />
                        <!-- <statecity v-bind:address="bnk" v-bind:addressType="'bank'"  v-bind:requiredCntry="false" />                                             -->
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="form_section white_bg suppliers_section">
                <form>
                  <div>
                    <h4 class="title">
                      Supplier Info
                      <span>
                        <img
                          :id="'supplierInfo'"
                          style="width: 14px; margin-top: -4px;"
                          src="@/assets/images/info_icon.svg"
                        />
                        <b-tooltip :target="'supplierInfo'">
                          <p>
                            Required for California registration
                            https://www.cdtfa.ca.gov/formspubs/pub107/applying-for-a-sellers-permit.htm
                          </p>
                        </b-tooltip>
                      </span>
                    </h4>
                    <div class="supplier-details">
                      <template v-for="(supplier, index) in Payload.supplier">
                        <div :key="index" class="suppliers_list">
                          <div class="suppliers_cnt">
                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label class="form_label">Type</label>
                                  <ValidationProvider
                                    name="Supplier Type"
                                    v-slot="{ errors }"
                                  >
                                    <multiselect
                                      v-model="supplier.typeDetails"
                                      :value="'_id'"
                                      :options="supplier_type"
                                      :searchable="true"
                                      :close-on-select="true"
                                      :allow-empty="true"
                                      :multiple="false"
                                      @input="updatesupplier(supplier)"
                                      label="name"
                                      placeholder="Select Supplier Type"
                                      track-by="name"
                                      :hide-selected="false"
                                    >
                                      <template
                                        slot="selection"
                                        slot-scope="{ values, isOpen }"
                                      >
                                        <span
                                          class="multiselect__selectcustom"
                                          v-if="values.length && !isOpen"
                                          >{{ values.length }} Supplier Type (s)
                                          selected</span
                                        >
                                        <span
                                          class="multiselect__selectcustom"
                                          v-if="values.length && isOpen"
                                        ></span>
                                      </template>
                                    </multiselect>

                                    <span
                                      class="v-messages error-msg"
                                      v-if="errors[0]"
                                      >{{ errors[0] }}</span
                                    >
                                  </ValidationProvider>
                                </div>
                              </div>
                            </div>
                            <div
                              class="delete_btn"
                              v-if="Payload.supplier.length > 1"
                              @click="removeSuplier(index)"
                            >
                              <b-icon icon="trash" aria-hidden="true"></b-icon>
                            </div>
                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label class="form_label"
                                    >Name a supplier/vendor in the US
                                  </label>
                                  <ValidationProvider
                                    :name="'Supplier Name' + index"
                                    rules="max:50"
                                    v-slot="{ errors }"
                                  >
                                    <input
                                      type="text"
                                      @blur="autoSave"
                                      class="form-control"
                                      name="MarketPlace"
                                      v-model="supplier.name"
                                    />
                                    <span
                                      class="v-messages error-msg"
                                      v-if="errors[0]"
                                      >{{
                                        supplier.name.length > 50
                                          ? "Supplier Name allow only 50 characters"
                                          : "Supplier Name is required"
                                      }}
                                    </span>
                                  </ValidationProvider>
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label class="form_label">Phone Number</label>
                                  <ValidationProvider
                                    name="Supplier Phone Number"
                                    rules="phonenumber"
                                    v-slot="{ errors }"
                                  >
                                    <VuePhoneNumberInput
                                      default-country-code="US"
                                      :no-example="true"
                                      :only-countries="['US']"
                                      :no-country-selector="true"
                                      @update="updatePhoneNumber"
                                      :required="true"
                                      v-model="supplier.phone"
                                    />
                                    <span
                                      class="v-messages error-msg"
                                      v-if="errors[0]"
                                      >{{ errors[0] }}</span
                                    >
                                  </ValidationProvider>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label class="form_label"
                                    >Email Address</label
                                  >
                                  <ValidationProvider
                                    :name="'Supplier Email'"
                                    rules="email"
                                    v-slot="{ errors }"
                                  >
                                    <input
                                      type="text"
                                      @blur="autoSave"
                                      class="form-control"
                                      name="MarketPlace"
                                      v-model="supplier.email"
                                    />
                                    <span
                                      class="v-messages error-msg"
                                      v-if="errors[0]"
                                      >{{ errors[0] }}
                                    </span>
                                  </ValidationProvider>
                                </div>
                              </div>
                            </div>
                            <addressComponent
                              v-bind:autoSave="autoSave"
                              v-if="ClientDataloaded && supplier.typeDetails"
                              v-bind:address="supplier.address"
                              v-bind:addressType="'supplier'"
                            />
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                  <div @click="addSuplier()" class="primary_btn add_more">
                    Add Supplier
                  </div>
                </form>
              </div>
              <div class="form_section inventory_section">
                <form>
                  <div>
                    <h4 class="title">Inventory Location - Type of Location</h4>
                    <label class="form_subtitle"
                      >Please provide addresses for all locations where
                      inventory is stored in the US. (i.e. warehouse,
                      manufacturing plant, third-party fulfillment center,
                      retail stores)
                    </label>
                    <div class="inventory-details">
                      <template
                        v-for="(inventory, index) in Payload.inventoryLocations"
                      >
                        <div :key="index" class="inventory_list">
                          <div class="inventory_cnt">
                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label class="form_label">Type</label>
                                  <ValidationProvider
                                    name="Market Place"
                                    rules="required"
                                    v-slot="{ errors }"
                                  >
                                    <BaseRadioButtonGroup
                                      :options="inventory_location_type"
                                      v-model="inventory.typeDetails"
                                      @input="updateinventory(inventory)"
                                      :selectedValue="inventory.typeId"
                                      :name="'inventory_location_type_' + index"
                                    />

                                    <input
                                      v-model="inventory.otherInventoryLocation"
                                      type="text"
                                      placeholder="Enter other Inventory Location"
                                      class="form-control"
                                      v-if="
                                        inventory.typeDetails &&
                                          inventory.typeDetails._id == 7
                                      "
                                    />

                                    <!--<multiselect
                                      v-model="inventory.typeDetails"
                                      :value="'_id'"
                                      :options="inventory_location_type"
                                      :searchable="true"
                                      :close-on-select="true"
                                      :allow-empty="true"
                                      :multiple="false"
                                      @input="updateinventory(inventory)"
                                      label="name"
                                      placeholder="Select Inventory Type"
                                      track-by="name"
                                      :hide-selected="false"
                                    >
                                      <template
                                        slot="selection"
                                        slot-scope="{ values, isOpen }"
                                      >
                                        <span
                                          class="multiselect__selectcustom"
                                          v-if="values.length && !isOpen"
                                          >{{ values.length }} Inventory
                                          Location (s) selected</span
                                        >
                                        <span
                                          class="multiselect__selectcustom"
                                          v-if="values.length && isOpen"
                                        ></span>
                                      </template>
                                    </multiselect>
                                    -->

                                    <span
                                      class="v-messages error-msg"
                                      v-if="errors[0]"
                                      >{{ errors[0] }}</span
                                    >
                                  </ValidationProvider>
                                </div>
                              </div>
                            </div>

                            <div
                              class="delete_btn"
                              v-if="Payload.inventoryLocations.length > 1"
                              @click="removeInventory(index)"
                            >
                              <b-icon icon="trash" aria-hidden="true"></b-icon>
                            </div>

                            <addressComponent
                              v-bind:autoSave="autoSave"
                              v-if="ClientDataloaded"
                              v-bind:address="inventory.address"
                              v-bind:addressType="'inventory' + index"
                            />
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                  <div @click="addInventory()" class="primary_btn add_more">
                    Add Inventory
                  </div>
                </form>
              </div>
            </ValidationObserver>
          </div>
          <div id="owner_details" v-if="blocks.form3.active">
            <ValidationObserver ref="owner_details">
              <div class="form_title">
                <h3>Owners/Officers/Responsible parties</h3>
                <p>
                  *Please ensure the information on this form is correct,
                  including spelling, this is the exact information we give to
                  the states
                </p>
              </div>

              <div>
                <template v-for="(owner, index) in Payload.owners">
                  <div :key="index" class="owner_details_list">
                    <!-- <div v-if="Payload.owners.length>1" @click="removeOwner(index)"><h2>Remove</h2></div> -->
                    <div class="form_title padt0">
                      <div class="form_section" style="padding-top: 10px">
                        <form>
                          <div
                            class="form-group upload_sec_wrap"
                            style="margin-left: 0"
                          >
                            <div class="upload_sec">
                              <label class="form_label">Company Logo</label>
                              <template v-if="Payload['logo'] != ''">
                                <div class="logo_uploded">
                                  <figure>
                                    <img
                                      class="file-icon"
                                      @error="setAltImg"
                                      :src="Payload['logo']"
                                      @click="documents = []"
                                    />
                                    <span @click="removeLogo()" class="delete"
                                      ><b-icon
                                        icon="trash"
                                        aria-hidden="true"
                                      ></b-icon
                                    ></span>
                                  </figure>
                                </div>
                              </template>
                              <template v-else>
                                <file-upload
                                  v-model="documents"
                                  class="file-upload-input"
                                  :accept="'.jpg,.png,.jpeg'"
                                  :name="'logo'"
                                  data-vv-as=" documents"
                                  :multiple="false"
                                  @input="uploadLogo(documents)"
                                >
                                  <img
                                    class="file-icon"
                                    src="@/assets/images/upload_icon.svg"
                                    @click="documents = []"
                                  />

                                  Logo
                                </file-upload>
                              </template>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <div class="form-group">
                                <label class="form_label">
                                  <template v-if="index > 0">
                                    Full name of Secondary owner
                                  </template>
                                  <template v-else>
                                    Full name of Primary owner
                                  </template>
                                </label>
                                <ValidationProvider
                                  :name="'Owner Name' + index"
                                  rules="required|max:50"
                                  v-slot="{ errors }"
                                >
                                  <input
                                    type="text"
                                    @blur="autoSave"
                                    class="form-control"
                                    name="MarketPlace"
                                    v-model="owner.name"
                                  />
                                  <span
                                    class="v-messages error-msg"
                                    v-if="errors[0]"
                                    >{{
                                      owner.name.length > 50
                                        ? "Allow only 50 characters"
                                        : "Owner Name is required"
                                    }}
                                  </span>
                                </ValidationProvider>
                              </div>
                            </div>
                            <div class="col">
                              <div class="form-group">
                                <label class="form_label"
                                  >Title (CEO, Founder etc)</label
                                >
                                <ValidationProvider
                                  :name="'owner' + index"
                                  rules="required|max:25"
                                  v-slot="{ errors }"
                                >
                                  <input
                                    type="text"
                                    @blur="autoSave"
                                    class="form-control"
                                    name="MarketPlace"
                                    v-model="owner.title"
                                  />
                                  <span
                                    class="v-messages error-msg"
                                    v-if="errors[0]"
                                  >
                                    {{
                                      owner.title.length > 25
                                        ? "Allow only 25 characters"
                                        : "Owner title is required"
                                    }}
                                  </span>
                                </ValidationProvider>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <div class="form-group date_desable">
                                <!-- <label class="form_label">Phone Number</label>
                                                        <phoneInput v-bind:payLoadKey="'owners'" @updatePhc="updatePhc" v-bind:addressType="'owners'+index" v-bind:index="index" v-bind:pnumber="owner.phone" v-bind:pcode="owner.phoneCode"  /> -->
                                <!-- <phoneInput v-bind:payLoadKey="'owners'" v-bind:addressType="'owners'+index" v-bind:index="index" v-bind:pnumber="owner.phone" v-bind:pcode="owner.phoneCode"  @updatePhc="updatePhc" />-->

                                <label class="form_label">Phone Number</label>
                                <ValidationProvider
                                  name="Phone Number"
                                  rules="required|phonenumber"
                                  v-slot="{ errors }"
                                >
                                  <VuePhoneNumberInput
                                    default-country-code="US"
                                    :no-example="true"
                                    :only-countries="['US']"
                                    :no-country-selector="true"
                                    @update="updatePhoneNumber"
                                    :required="true"
                                    v-model="owner.phone"
                                  />
                                  <span
                                    class="v-messages error-msg"
                                    v-if="errors[0]"
                                    >{{ errors[0] }}</span
                                  >
                                </ValidationProvider>
                              </div>
                            </div>
                            <div class="col">
                              <div class="form-group">
                                <label class="form_label">Email Address</label>
                                <ValidationProvider
                                  :name="'Owner Email'"
                                  rules="required|email"
                                  v-slot="{ errors }"
                                >
                                  <input
                                    type="text"
                                    @blur="autoSave"
                                    class="form-control"
                                    name="Email Address"
                                    v-model="owner.email"
                                  />
                                  <span
                                    class="v-messages error-msg"
                                    v-if="errors[0]"
                                    >{{ errors[0] }}
                                  </span>
                                </ValidationProvider>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <div class="form-group">
                                <label class="form_label"
                                  >Percent (%) of ownership</label
                                >
                                <ValidationProvider
                                  :name="'owner sharePercent' + index"
                                  rules="required|percentage"
                                  v-slot="{ errors }"
                                >
                                  <input
                                    type="number"
                                    class="form-control"
                                    name="MarketPlace"
                                    v-model="owner.sharePercent"
                                    min="1"
                                    max="100"
                                  />
                                  <span
                                    class="v-messages error-msg"
                                    v-if="errors[0]"
                                    >{{
                                      errors[0]
                                        ? !owner.sharePercent
                                          ? "Percentage is required"
                                          : errors[0]
                                        : ""
                                    }}
                                  </span>
                                </ValidationProvider>
                              </div>
                            </div>
                            <div class="col">
                              <div class="form-group">
                                <label class="form_label">SSN</label>
                                <ValidationProvider
                                  :name="'Owner SSN'"
                                  rules="required|min:5|max:15"
                                  v-slot="{ errors }"
                                >
                                  <input
                                    type="text"
                                    @blur="autoSave"
                                    class="form-control"
                                    name="SNN"
                                    @input="formatSSN($event, owner)"
                                    v-model="owner.SSN"
                                  />
                                  <span
                                    class="v-messages error-msg"
                                    v-if="errors[0]"
                                  >
                                    {{ errors[0] }}
                                  </span>
                                </ValidationProvider>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <span class="devider"></span>
                    </div>

                    <div class="form_title padt0">
                      <div class="form_section" style="padding-top: 0;">
                        <form>
                          <h4 class="title">Drivers License</h4>
                          <div class="row">
                            <div class="col">
                              <div class="form-group">
                                <label class="form_label"
                                  >Upload copy of Drivers License
                                </label>
                                <div class="upload_sec">
                                  <uploadFile
                                    @onUploadComplete="onUploadComplete"
                                    :allowultiple="true"
                                    :acceptedFiles="'*'"
                                    :indexValue="index"
                                    :takeIndex="true"
                                    :payLoadKey="'owners'"
                                    :payLoadDocumentsKey="'driverLicenseDocs'"
                                  />
                                </div>
                                <ul class="uploaded-list">
                                  <template
                                    v-for="(other,
                                    indx) in owner.driverLicenseDocs"
                                  >
                                    <li :key="indx">
                                      {{ other.name }}
                                      <span
                                        @click="
                                          removeDoc(
                                            indx,
                                            owner.driverLicenseDocs
                                          )
                                        "
                                        >X</span
                                      >
                                    </li>
                                  </template>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col">
                              <div class="form-group">
                                <label class="form_label"
                                  >Driver’s License Number</label
                                >
                                <ValidationProvider
                                  :name="'License Number'"
                                  rules="required|Alphanumeric|max:15"
                                  v-slot="{ errors }"
                                >
                                  <input
                                    type="text"
                                    @blur="autoSave"
                                    class="form-control"
                                    name="Driver’s License Number"
                                    v-model="owner.driverLicenseNo"
                                  />
                                  <span
                                    class="v-messages error-msg"
                                    v-if="errors[0]"
                                    >{{ errors[0] }}
                                  </span>
                                </ValidationProvider>
                              </div>
                            </div>
                            <div class="col">
                              <div class="form-group">
                                <label class="form_label">Issued State</label>

                                <!-- <ValidationProvider name="Please list the states" rules="required" v-slot="{ errors }">
                                                           <multiselect
                                                                v-model="owner.licensedStateDetails"
                                                                :value="'_id'"
                                                                :options="usstateList"
                                                                :searchable="true"
                                                                :close-on-select="true"
                                                                :allow-empty="false"
                                                                :multiple="false"
                                                                @input="updateOwnerLicensedState"
                                                                label="name"
                                                                placeholder="Select Licensed State"
                                                                track-by="name"


                                                                >
                                                            </multiselect>

                                                            <span  class="v-messages error-msg" v-if="errors[0]">{{ errors[0]?'Licensed State required':'' }}</span>
                                                        </ValidationProvider>-->

                                <multiselectBox
                                  v-if="ClientDataloaded"
                                  v-bind:indexKey="'licensedState'"
                                  v-bind:licensedState="owner.licensedState"
                                  v-bind:payLoadKey="'owners'"
                                  @updateOwnerLicensedState="
                                    updateOwnerLicensedState
                                  "
                                  v-bind:addressType="'state' + index"
                                  v-bind:index="index"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col">
                              <div class="form-group">
                                <label class="form_label"
                                  >Issue Date
                                </label>
                                <div
                                  id="duedate"
                                  class="input-group date"
                                  data-date-format="dd-mm-yyyy"
                                >
                                  <ValidationProvider
                                    :name="'Title Issue Date' + index"
                                    rules="required"
                                    v-slot="{ errors }"
                                  >
                                    <datepicker
                                      :open-date="new Date(openDate)"
                                      :disabled-dates="{
                                        from: new Date(startEligibleDate),
                                      }"
                                      :required="true"
                                      :typeable="true"
                                      :format="customFormatter"
                                      v-model="owner.titleEffDate"
                                      :name="'ownerEffectiveDate_' + index"
                                    ></datepicker>
                                    <!--<datepicker  :disabled-dates="{from: new Date(startEligibleDate)}"   :required="true" :format="customFormatter" v-model="owner.titleEffDate" name="uniquename"></datepicker>-->

                                    <span class="input-group-addon"
                                      ><i
                                        class="glyphicon glyphicon-calendar"
                                      ></i
                                    ></span>
                                    <span
                                      class="v-messages error-msg"
                                      v-if="errors[0]"
                                      >{{
                                        errors[0]
                                          ? "Title Issue Date is required"
                                          : ""
                                      }}</span
                                    >
                                  </ValidationProvider>
                                </div>
                              </div>
                            </div>
                            <div class="col">
                              <div class="form-group">
                                <label class="form_label"
                                  >Expiration Date
                                </label>
                                <div
                                  id="duedate"
                                  class="input-group date"
                                  data-date-format="dd-mm-yyyy"
                                >
                                  <ValidationProvider
                                    :name="'Title Expiration Date' + index"
                                    rules="required"
                                    v-slot="{ errors }"
                                  >
                                    <datepicker
                                      :open-date="new Date(openDate)"
                                      :disabled-dates="{
                                        from: new Date(startEligibleDate),
                                      }"
                                      :required="true"
                                      :typeable="true"
                                      :format="customFormatter"
                                      v-model="owner.titleExpDate"
                                      :name="'ownerExpirationDate_' + index"
                                    ></datepicker>

                                    <span class="input-group-addon"
                                      ><i
                                        class="glyphicon glyphicon-calendar"
                                      ></i
                                    ></span>
                                    <span
                                      class="v-messages error-msg"
                                      v-if="errors[0]"
                                      >{{
                                        errors[0]
                                          ? "Title Expiration Date is required"
                                          : ""
                                      }}</span
                                    >
                                  </ValidationProvider>
                                </div>
                              </div>
                            </div>
                            <div class="col">
                              <div class="form-group">
                                <label class="form_label">Date of Birth</label>
                                <div
                                  id="duedate"
                                  class="input-group date"
                                  data-date-format="dd-mm-yyyy"
                                >
                                  <ValidationProvider
                                    :name="'Date of Birth' + index"
                                    rules="required"
                                    v-slot="{ errors }"
                                  >
                                    <datepicker
                                      :disabled-dates="{ from: minDateOfBirth }"
                                      :required="true"
                                      :typeable="true"
                                      :format="'yyyy-MM-dd'"
                                      v-model="owner.dateOfBirth"
                                      name="uniquename"
                                    ></datepicker>

                                    <span class="input-group-addon"
                                      ><i
                                        class="glyphicon glyphicon-calendar"
                                      ></i
                                    ></span>
                                    <span
                                      class="v-messages error-msg"
                                      v-if="errors[0]"
                                      >{{
                                        errors[0]
                                          ? "Date of Birth is required"
                                          : ""
                                      }}</span
                                    >
                                  </ValidationProvider>
                                  <span class="input-group-addon"
                                    ><i class="glyphicon glyphicon-calendar"></i
                                  ></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <span class="devider"></span>
                    </div>

                    <div class="form_section">
                      <form>
                        <div>
                          <h4 class="title">Address</h4>
                          <!-- <addressComponent
                                                 v-bind:autoSave="autoSave"
 v-bind:address="bnk.address" v-bind:addressType="'bank'+indx" /> -->
                          <addressComponent
                            v-bind:autoSave="autoSave"
                            v-bind:address="owner.address"
                            v-bind:addressType="'ownerAddress' + index"
                          />
                        </div>
                        <div class="d-flex justify-content-end">
                          <div
                            v-if="Payload.owners.length > 1"
                            @click="removeOwner(index)"
                            class="secondary_btn remove_btn"
                          >
                            Remove
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </template>
              </div>
            </ValidationObserver>

            <div @click="addOwner()" class="primary_btn add_more side-padding">
              Add More Owners/Officers/Responsible Parties
            </div>
          </div>
          <div id="statesalreadyregistered" v-if="blocks.form4.active">
            <ValidationObserver ref="statesalreadyregistered">
              <div class="form_title">
                <h3>Registered States</h3>

                <p>
                  *Please ensure the information on this form is correct,
                  including spelling, this is the exact information we give to
                  the states
                </p>
                <div class="form_section padt30">
                  <form>
                    <span class="devider"></span>
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <label class="form_label"
                            >Choose Registered States</label
                          >
                          <ValidationProvider
                            name="Please list the states"
                            v-slot="{ errors }"
                          >
                            <multiselect
                              v-model="Payload.stateRegs"
                              :value="'_id'"
                              :options="usstateList"
                              :searchable="true"
                              :close-on-select="false"
                              :allow-empty="true"
                              :multiple="true"
                              @input="addstateReg"
                              label="name"
                              placeholder="Select Licensed State"
                              track-by="name"
                              :hide-selected="true"
                            >
                              <template
                                slot="selection"
                                slot-scope="{ values, isOpen }"
                              >
                                <span
                                  class="multiselect__selectcustom"
                                  v-if="values.length && !isOpen"
                                  >{{ values.length }} States selected</span
                                >
                                <span
                                  class="multiselect__selectcustom"
                                  v-if="values.length && isOpen"
                                ></span>
                              </template>
                            </multiselect>

                            <span
                              class="v-messages error-msg"
                              v-if="errors[0]"
                              >{{ errors[0] ? "State is required" : "" }}</span
                            >
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="form_section step4">
                <div class="accordian_wrap white_bg">
                  <div class="accordion" role="tablist">
                    <template v-for="(stateReg, index) in Payload.stateReg">
                      <div
                        class="accordian_list"
                        :key="index"
                        v-if="stateReg.status"
                      >
                        <div class="accordian_title">
                          <h4 @click="showToggle(index, stateReg)">
                            <span>{{
                              stateReg.stateDetails.name | getTwolatters
                            }}</span
                            >{{ stateReg.stateDetails.name }}
                          </h4>
                          <ul>
                            <!-- <li>
                                                            <b-icon icon="pencil-square" ></b-icon>
                                                        </li> -->
                            <li
                              v-if="Payload.stateRegs.length > 1"
                              @click="deleteRegstate(index, stateReg)"
                            >
                              <b-icon icon="trash"></b-icon>
                            </li>
                            <li
                              class="expand_btn "
                              @click="showToggle(index, stateReg)"
                              :class="{ 'not-collapsed': stateReg.show }"
                            >
                              <span></span>
                            </li>
                          </ul>
                        </div>
                        <!-- v-if="stateReg.show"-->
                        <div class="accordian_cnt_wrap" v-if="stateReg.show">
                          <div class="accordian_cnt">
                            <div class="form_section">
                              <form>
                                <!-- <div class="info">
                                                                    <p>Please enter username/password</p>
                                                                </div> -->
                                <div class="row">
                                  <div class="col">
                                    <div class="form-group">
                                      <label class="form_label"
                                        >Username
                                      </label>
                                      <template v-if="!stateReg.hasCredentials">
                                        <ValidationProvider
                                          :name="'User name' + index"
                                          rules="required|max:25"
                                          v-slot="{ errors }"
                                        >
                                          <input
                                            v-if="!stateReg.hasCredentials"
                                            type="text"
                                            class="form-control"
                                            :name="'user_name' + index"
                                            v-model="stateReg.userName"
                                          />
                                          <span
                                            class="v-messages error-msg"
                                            v-if="errors[0]"
                                            >{{
                                              stateReg.userName.length > 25
                                                ? "Allow only 25 characters"
                                                : "User Name is required"
                                            }}
                                          </span>
                                        </ValidationProvider>
                                      </template>
                                      <template v-else>
                                        <input
                                          type="text"
                                          :disabled="true"
                                          class="form-control"
                                          :name="'username' + index"
                                          value="*****"
                                        />
                                      </template>
                                    </div>
                                  </div>
                                  <div class="col view_password_wrap">
                                    <div class="form-group view_password">
                                      <label class="form_label"
                                        >Password
                                      </label>
                                      <template v-if="!stateReg.hasCredentials">
                                        <ValidationProvider
                                          :name="'Username' + index"
                                          rules="required|max:25"
                                          v-slot="{ errors }"
                                        >
                                          <input
                                            type="text"
                                            class="form-control"
                                            :name="'password' + index"
                                            v-model="stateReg.password"
                                          />
                                          <span
                                            class="v-messages error-msg"
                                            v-if="errors[0]"
                                            >{{
                                              stateReg.password.length > 25
                                                ? "Allow only 25 characters"
                                                : "Password is required"
                                            }}
                                          </span>
                                        </ValidationProvider>
                                      </template>
                                      <template v-else>
                                        <input
                                          type="text"
                                          :disabled="true"
                                          class="form-control"
                                          :name="'password' + index"
                                          value="*****"
                                        />
                                      </template>
                                    </div>
                                    <!--@@@@@ {{checkProperty(stateReg ,'_id')}} @@@@@ -->
                                    <template
                                      v-if="checkProperty(stateReg, '_id')"
                                    >
                                      <template v-if="stateReg.hasCredentials">
                                        <div
                                          class="eye_icon"
                                          title="show"
                                          @click="
                                            getCredentials(index, stateReg)
                                          "
                                        >
                                          <!-- <b-icon icon="" aria-hidden="true"></b-icon> -->
                                          <b-icon
                                            icon="eye-slash-fill"
                                            aria-hidden="true"
                                          ></b-icon>
                                        </div>
                                      </template>
                                      <template v-else>
                                        <div
                                          class="eye_icon"
                                          title="hide"
                                          @click="
                                            hideCredentials(index, stateReg)
                                          "
                                        >
                                          <b-icon
                                            icon=" eye-fill"
                                            aria-hidden="true"
                                          ></b-icon>
                                        </div>
                                      </template>
                                    </template>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col">
                                    <div class="form-group">
                                      <label class="form_label"
                                        >Estimated Sales</label
                                      >
                                      <ValidationProvider
                                        :name="'Estimated Sales' + index"
                                        rules="required"
                                        v-slot="{ errors }"
                                      >
                                        <input
                                          type="number"
                                          min="0"
                                          class="form-control"
                                          :name="'Estimated Sales' + index"
                                          v-model="stateReg.estimatedSales"
                                        />
                                        <span
                                          class="v-messages error-msg"
                                          v-if="errors[0]"
                                          >{{
                                            errors[0]
                                              ? "Estimated Sales is required"
                                              : ""
                                          }}
                                        </span>
                                      </ValidationProvider>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col">
                                    <div class="form-group">
                                      <label class="form_label"
                                        >Payment Frequency</label
                                      >
                                      <!-- <multiselect
                                                                                v-model="Payload.stateRegs"
                                                                                :value="'_id'"
                                                                                :options="usstateList"
                                                                                :searchable="true"
                                                                                :close-on-select="true"
                                                                                :allow-empty="false"
                                                                                :multiple="false"
                                                                                @input="addstateReg"
                                                                                label="name"
                                                                                placeholder="Select Licensed State"
                                                                                track-by="name"

                                                                                >
                                                                            </multiselect>-->

                                      <multiselectBox
                                        v-bind:masterDataKey="'pay_frequency'"
                                        v-if="ClientDataloaded"
                                        v-bind:indexKey="'payFrequencyId'"
                                        v-bind:licensedState="
                                          parseInt(stateReg.payFrequencyId)
                                        "
                                        v-bind:payLoadKey="'stateReg'"
                                        @updateOwnerLicensedState="
                                          updateOwnerLicensedState
                                        "
                                        v-bind:addressType="
                                          'payFrequencyId' + index
                                        "
                                        v-bind:index="index"
                                      />
                                    </div>
                                  </div>
                                  <!--<div class="col">
                                    <div class="form-group">
                                      <label class="form_label"
                                        >Payment On</label
                                      >
                                      <ValidationProvider
                                        :name="'PaymentOn' + index"
                                        rules="required"
                                        v-slot="{ errors }"
                                      >
                                        <input
                                          type="number"
                                          min="1"
                                          class="form-control"
                                          :name="'PaymentOn' + index"
                                          v-model="stateReg.paymentOn"
                                        />
                                        <span
                                          class="v-messages error-msg"
                                          v-if="errors[0]"
                                          >{{
                                            errors[0]
                                              ? "Payment On is required"
                                              : ""
                                          }}
                                        </span>
                                      </ValidationProvider>
                                    </div>
                                  </div>-->
                                  <div class="col">
                                    <div class="form-group">
                                      <label class="form_label"
                                        >Sales Tax Account Number</label
                                      >
                                      <ValidationProvider
                                        :name="'SalesTaxAccntNo' + index"
                                        rules=""
                                        v-slot="{ errors }"
                                      >
                                        <input
                                          type="text"
                                          class="form-control"
                                          :name="'SalesTaxAccntNo' + index"
                                          v-model="stateReg.salesTaxAccntNo"
                                        />
                                        <span
                                          class="v-messages error-msg"
                                          v-if="errors[0]"
                                          >{{
                                            errors[0]
                                              ? "Payment On is required"
                                              : ""
                                          }}
                                        </span>
                                      </ValidationProvider>
                                    </div>
                                  </div>
                                  <!--<div class="col">
                                    <div class="form-group">
                                      <label class="form_label"
                                        >Last Calculated Date
                                      </label>
                                      <div
                                        id="duedate"
                                        class="input-group date"
                                        data-date-format="dd-mm-yyyy"
                                      >-->
                                  <!-- :disabled="checkProperty(stateReg ,'trxnStartDate')!='--' " --->
                                  <!--<ValidationProvider
                                          :name="'trxnStartDate' + index"
                                          rules="required"
                                          v-slot="{ errors }"
                                        >
                                          <datepicker
                                            :open-date="new Date(openDate)"
                                            :disabled-dates="{
                                              from: new Date(startEligibleDate),
                                            }"
                                            :required="true"
                                            :format="customFormatter"
                                            v-model="stateReg.trxnStartDate"
                                            :name="'trxnStartDate_' + index"
                                          ></datepicker>

                                          <span class="input-group-addon"
                                            ><i
                                              class="glyphicon glyphicon-calendar"
                                            ></i
                                          ></span>
                                          <span
                                            class="v-messages error-msg"
                                            v-if="errors[0]"
                                            >{{
                                              errors[0]
                                                ? "Last Calculated Date is Required"
                                                : ""
                                            }}</span
                                          >
                                        </ValidationProvider>
                                      </div>
                                    </div>
                                  </div>-->
                                </div>
                                <div class="row">
                                  <div class="col">
                                    <div class="form-group">
                                      <label class="form_label"
                                        >Security Question</label
                                      >
                                      <ValidationProvider
                                        :name="'securityQuestion' + index"
                                        rules=""
                                        v-slot="{ errors }"
                                      >
                                        <input
                                          type="text"
                                          class="form-control"
                                          :name="'securityQuestion' + index"
                                          v-model="stateReg.securityQuestion"
                                        />
                                        <span
                                          class="v-messages error-msg"
                                          v-if="errors[0]"
                                          >{{
                                            errors[0]
                                              ? "SecurityQuestion is required"
                                              : ""
                                          }}
                                        </span>
                                      </ValidationProvider>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label class="form_label"
                                        >Security Answer</label
                                      >
                                      <ValidationProvider
                                        :name="'SecurityAnswer' + index"
                                        rules=""
                                        v-slot="{ errors }"
                                      >
                                        <input
                                          type="text"
                                          class="form-control"
                                          :name="'SecurityAnswer' + index"
                                          v-model="stateReg.securityAnswer"
                                        />
                                        <span
                                          class="v-messages error-msg"
                                          v-if="errors[0]"
                                          >{{
                                            errors[0]
                                              ? "SecurityAnswer is required"
                                              : ""
                                          }}
                                        </span>
                                      </ValidationProvider>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col">
                                    <div class="form-group marb10">
                                      <label class="form_label"
                                        >Sales Tax Permit</label
                                      >
                                      <div class="upload_sec">
                                        <uploadFile
                                          @onUploadComplete="onUploadComplete"
                                          :allowMultiple="true"
                                          :acceptedFiles="'*'"
                                          :indexValue="index"
                                          :takeIndex="true"
                                          :payLoadKey="'stateReg'"
                                          :payLoadDocumentsKey="'documents'"
                                        />
                                      </div>

                                      <ul class="uploaded-list">
                                        <template
                                          v-for="(other,
                                          indx) in stateReg.documents"
                                        >
                                          <li :key="indx">
                                            {{ other.name }}
                                            <span
                                              @click="
                                                removeDoc(
                                                  indx,
                                                  stateReg.documents
                                                )
                                              "
                                              >X</span
                                            >
                                          </li>
                                        </template>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <!-- <b-form-checkbox
                                                                        :id="'checkbox-'+index"
                                                                        v-model="stateReg.operating"
                                                                        :name="'checkbox-'+index"
                                                                        :value="true"
                                                                        :unchecked-value="false"
                                                                        >
                                                                        In operating
                                                                    </b-form-checkbox> -->
                                  <div class="col">
                                    <input
                                      class="styled-checkbox"
                                      :id="'operating' + index"
                                      @click="isOperating($event, index)"
                                      v-model="stateReg.operating"
                                      type="checkbox"
                                      :value="stateReg.operating"
                                    />
                                    <label :for="'operating' + index"
                                      >In operating</label
                                    >
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div class="form_section pt-0">
                <form>
                  <span class="devider"></span>
                  <div class="row">
                    <div class="col">
                      <!--<div class="form-group">
                        <label class="form_label"
                          >Would you like TaxHero Inc. to be the mailing address
                          for all sales tax related letters, notices and
                          permits?</label
                        >
                        <ValidationProvider
                          name="TaxHero"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <multiselect
                            v-model="Payload.wouldYouLikeTaxHero"
                            :options="likeTypes"
                            :searchable="true"
                            :close-on-select="true"
                            :allow-empty="false"
                            :multiple="false"
                            placeholder="Select"
                          >
                          </multiselect>
                          <span class="v-messages error-msg" v-if="errors[0]">{{
                            errors[0]
                          }}</span>
                        </ValidationProvider>
                      </div>-->
                    </div>
                  </div>
                </form>
              </div>
            </ValidationObserver>
          </div>
        </div>
        <div class="register_form_actions">
          <button
            v-if="clientData.statusId <= 1"
            class="secondary_btn"
            @click="saveQuestionnaire()"
          >
            Save
          </button>

          <!-- <button v-if="show_block <= 3"  class="primary_btn" @click="getNextForm()"  >Next</button> -->

          <button
            v-if="show_block <= 3"
            class="primary_btn"
            @click="getNextForm()"
          >
            <template v-if="clientData.statusId <= 1">
              Save & Continue</template
            >
            <template v-else> Update & Continue </template>
          </button>
          <!--  this.clientData.statusId-->

          <button
            v-if="show_block > 3"
            @click="
              getNextForm();
              saveQuestionnaire('Yes');
            "
            class="primary_btn"
          >
            <template v-if="clientData.statusId <= 1">Submit</template>
            <template v-else>Update</template>
          </button>
        </div>
      </div>
    </div>
    <div v-if="showLoading" class="page_loader">
      <img src="@/assets/images/loader.gif" />
    </div>
  </div>
</template>

<script>
import profileHeader from "@/components/ProfileHeader.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import addressComponent from "@/components/address.vue";
// import statecity from "@/components/statecity.vue"
// import phoneInput from "@/components/phoneInput.vue"
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import _ from "lodash";
import Datepicker from "vuejs-datepicker";
import Multiselect from "vue-multiselect-inv";
import uploadFile from "@/components/uploadFile.vue";
import moment from "moment";
import multiselectBox from "@/components/multiselectBox.vue";
import FileUpload from "vue-upload-component/src";
import BaseRadioButtonGroup from "@/components/BaseRadioButtonGroup.vue";
//import uploadImage from "@/assets/images/upload_icon.svg"
import JQuery from "jquery";
import { extend } from "vee-validate";
import { Config } from "./../config";

// Define custom 'checked' rule
extend("checked", {
  validate(value) {
    return value === true; // Pass if the value is true (i.e., checkbox is checked)
  },
  message: "You must check this field",
});

// import { BIconBoundingBox } from "bootstrap-vue";

export default {
  name: "questionnaire",
  watch: {
    "Payload.emailHost"(newVal) {
      if (newVal !== 3) {
        this.Payload.customPlatformHosted = "";
      }
    },
    mailingAddressAsSame: function(val) {
      if (val === false) {
        if (
          (
            _.has(this.clientData.busAddress, "addr2") &&
            _.has(this.clientData.mailingAddress, "addr2") &&
            this.clientData.busAddress["addr2"] ===
              this.clientData.mailingAddress["addr2"] &&
            _.has(this.clientData.busAddress, "stateId") &&
            _.has(this.clientData.mailingAddress, "stateId") &&
            this.clientData.busAddress["stateId"] ===
              this.clientData.mailingAddress["stateId"] &&
            _.has(this.clientData.busAddress, "cityId") &&
            _.has(this.clientData.mailingAddress, "cityId") &&
            this.clientData.busAddress["cityId"] ===
              this.clientData.mailingAddress["cityId"] &&
            _.has(this.clientData.busAddress, "cntryId") &&
            _.has(this.clientData.mailingAddress, "cntryId") &&
            this.clientData.busAddress["cntryId"] ===
              this.clientData.mailingAddress["cntryId"] &&
            _.has(this.clientData.busAddress, "zip") &&
            _.has(this.clientData.mailingAddress, "zip") &&
            this.clientData.busAddress["zip"] ===
              this.clientData.mailingAddress["zip"]
          ) || (
            _.has(this.clientData.mailingAddress, "addr2") &&
              this.clientData.mailingAddress["addr2"] === Config.taxheroMailingAddress.addr2 &&
            _.has(this.clientData.mailingAddress, "stateId") &&
              this.clientData.mailingAddress["stateId"] === Config.taxheroMailingAddress.stateId &&
            _.has(this.clientData.mailingAddress, "cityId") &&
              this.clientData.mailingAddress["cityId"] === Config.taxheroMailingAddress.cityId &&
            _.has(this.clientData.mailingAddress, "cntryId") &&
              this.clientData.mailingAddress["cntryId"] === Config.taxheroMailingAddress.cntryId &&
            _.has(this.clientData.mailingAddress, "zip") &&
              this.clientData.mailingAddress["zip"] === Config.taxheroMailingAddress.zip
          )
        ) {
          this.Payload = {
            ...this.Payload,
            mailingAddress: {
              addr1: "",
              addr2: "",
              stateId: null,
              cityId: null,
              zip: "",
              cntryId: 231,
              countryDetails: null,
              stateDetails: null,
              locationDetails: null,
            }
          }
          
          return;
        }
      }

      if (val === null) {
        this.Payload = {
          ...this.Payload,
          mailingAddress: Config.taxheroMailingAddress,
        };
        return;
      }

      if (val) {
        this.Payload.mailingAddress = this.Payload.busAddress;
      } else {
        if (
          this.clientData != null &&
          _.has(this.clientData, "mailingAddress")
        ) {
          this.Payload.mailingAddress = this.clientData.mailingAddress;
        }
        //this.Payload.busAddress = this.Payload.mailingAddress;
      }
    },
  },
  mounted() {
    var date = new Date();
    (this.disabledFrom = new Date()),
      this.disabledFrom.setDate(this.disabledFrom.getDate() + 1);
    date.setFullYear(date.getFullYear() - 18);
    this.minDateOfBirth = date;

    this.userdata = this.$store.getters["auth/getuser"];
    this.show_block = 1;
    this.togleBlock();
    //   alert(JSON.stringify(this.userdata));

    /*"busContact": {
			"fName": "Thamous",
			"lName": "N",
			"phone": '',
			"phoneCode": "",
			"email": ""
		}*/

    this.getMaterData("inventory_location_type");
    this.getMaterData("supplier_type");
    this.getMaterData("market_place_type");
    this.getMaterData("llc_type");
    this.getMaterData("bus_structure");
    this.getMaterData("acc_method");
    this.getMaterData("bus_type");
    this.getMaterData("nexus");
    this.getMaterData("platform");
    this.getMaterData("pay_frequency");
    this.getMaterData("bank");
    this.getMaterData("email_host");
    this.getUsStatesList();
    console.log(this.$route);
    if (_.has(this.userdata, "clientDetails")) {
      this.Payload.clientId = this.userdata.clientDetails._id;
    } else if (_.has(this.$route.params, "clientId")) {
      this.Payload.clientId = this.$route.params["clientId"];
    }
    //clientData.statusId <=1
    this.getClientdetails();
  },
  provide() {
    return {
      parentValidator: this.$validator,
    };
  },
  components: {
    FileUpload,
    Multiselect,
    VuePhoneNumberInput,
    profileHeader,
    ValidationObserver,
    ValidationProvider,
    addressComponent,
    Datepicker,
    multiselectBox,
    // statecity,
    BaseRadioButtonGroup,
    uploadFile,
  },
  data() {
    return {
      filteredUsstateList: [],
      capTableDocs: "",
      market_place_type: [],
      inventory_location_type: [],
      supplier_type: [],
      purchaseAgreementDocs: "",
      minDateOfBirth: new Date(),
      userdata: null,
      documents: [],
      showDiv: true,
      mailingAddressAsSame: true,
      openDate: new Date().setFullYear(new Date().getFullYear()),
      startEligibleDate: new Date().setFullYear(new Date().getFullYear()),
      //   startEligibleDate: new Date(),
      startEligibleDate2: new Date() + 1,
      disabledFrom: new Date(),
      //       disabledDates: {
      //         from: new Date(),
      //   },
      show_block: 1,

      blocks: {
        form1: {
          active: true,
          validator: "legal_informationForm",
          label: "Legal Information",
          formIsValid: false,
        },
        form2: {
          active: false,
          validator: "business_information",
          label: "Business Information",
          formIsValid: false,
        },
        form3: {
          active: false,
          validator: "owner_details",
          label: "Owner Details",
          formIsValid: false,
        },
        form4: {
          active: false,
          validator: "statesalreadyregistered",
          label: "States already registered",
          formIsValid: false,
        },
      },

      clientData: null,
      email_host: [],
      showPassword: false,
      Payload: {
        emailHost: "",
        hostDetails: null,
        platformHosted: "",
        customPlatformHosted: "",
        emailPlatformHosted: "",
        passwordPlatformHosted: "",
        confirmedPlatformHosted: false,
        wouldYouLikeTaxHero: "",
        busEmail: "",
        busPhone: "",
        EIN: "",
        clientId: "",
        busContact: {
          fName: "Thamous",
          lName: "N",
          phone: "",
          phoneCode: "",
          email: "",
          website: "",
        },
        busName: "",
        tradeName: "",
        busAddress: {
          addr1: "",
          addr2: "",
          stateId: null,
          cityId: null,
          zip: "",
          cntryId: 231,
        },
        mailingAddress: {
          addr1: "",
          addr2: "",
          stateId: null,
          cityId: null,
          zip: "",
          cntryId: 231,
        },
        taxRecordLocation: {
          addr1: "",
          addr2: "",
          stateId: null,
          cityId: null,
          zip: "",
          cntryId: 231,
        },
        fedaralEmpId: "",
        busFormatedOn: "",
        busStructureId: "", /// master
        capTableDocs: [],
        busStructureDetails: null,
        busStructureDetailsOther: null,
        incorporatedState: "", /// master
        incorporatedStateDetails: null,
        secretaryStateNumbers: [{ number: "" }],
        LLCID: "423423", //Doubt
        llcTypeIds: [],
        llcTypeDetails: [],
        ownersMorethan20Percent: "",
        accMethodId: 2, /// master
        accMethodDetails: null,
        accClosedOn: "",
        nexusTypeId: "", /// master
        previousFilingEndDate: "",
        taxBegginingPeriod: 12,
        taxBegginingPeriodDetails: { name: "December", id: 12 },
        nexusTypeDetails: null,
        itemDescription: "",
        NAICSCode: "",
        busTypeId: "", /// master
        busTypeIds: [],
        businessTypeDetails: [],
        shopify: {
          clientKey: "",
          clientSecret: "",
        },
        doYouUserTaxzarApi: true,
        taxJarDescription: "",
        havePhysicalStore: false,
        physicalStoreAddress: {
          addr1: "",
          addr2: "",
          stateId: null,
          cityId: null,
          zip: "",
          cntryId: 231,
        },
        sellingPlatformIds: [], /// master
        platformDetails: null,
        shopifyUrlOrAddress: "",
        marketPlaceFecilitator: true,
        involvedInMerge: false,
        sellOnMarketPlace: false,
        marketPlaceSellId: [],
        otherMarketPlace: "",
        previousReturnNotifyChanges: false,
        previousReturnNotifyStates: [
          { reason: "", stateId: "", stateDetails: "" },
        ],
        marketPlaceSellDetails: [],
        marketPlace: "",
        purchaseExistingBusiness: false,
        purchaseAgreementDocs: [],
        haveEmployeesOrAgents: true,
        isSubsidaryOfParentCompany: false,
        parentCompanyName: "",
        otherBusStateIds: [], /// master
        otherBusStates: [],

        empPayrollStateIds: [],
        empPayrollStateDetails: [],

        website: "",
        taxNumber: "",
        articlesOfOrgAndDriverLiecenses: [],
        banks: [],
        industryIds: [],
        bankDetails: [],
        supplier: [
          {
            typeId: "",
            typeDetails: "",
            name: "",
            addr1: "",
            addr2: "",
            stateId: "",
            cityId: "",
            zip: "",
            cntryId: 231,
            phone: "",
            phoneCode: "",
            email: "",
          },
        ],
        inventoryLocations: [
          {
            typeId: null,
            typeDetails: "",
            otherInventoryLocation: "",
            address: {
              addr1: "",
              addr2: "",
              stateId: null,
              cityId: null,
              zip: "",
              cntryId: null,
              countryDetails: {
                _id: 231,
                sName: "US",
                name: "United States",
                phoneCode: 1,
                curSymbol: "$",
                curCode: "USD",
                zipLen: 5,
                phoneMin: 9,
                phoneMax: 10,
              },
              stateDetails: null,
              locationDetails: null,
            },
          },
        ],

        stateRegs: null,

        owners: [
          {
            name: "",
            title: "",
            titleEffDate: "",
            titleExpDate: "",
            address: {
              addr1: "",
              addr2: "",
              stateId: null,
              cityId: null,
              zip: "",
              cntryId: 231,
              countryDetails: {
                _id: 231,
                sName: "US",
                name: "United States",
                phoneCode: 1,
                curSymbol: "$",
                curCode: "USD",
                zipLen: 5,
                phoneMin: 9,
                phoneMax: 10,
              },
              stateDetails: null,
              locationDetails: null,
            },
            phone: "",
            phoneCode: "",
            email: "",
            dateOfBirth: "",
            SSN: "",
            sharePercent: "",
            driverLicenseNo: "",
            driverLicenseDocs: [],
            licensedState: "", // satateId of us Country
          },
        ],
        stateReg: [
          /*
            {
              "_id":"", // Required in update state registration
              "stateId": '', //dropdown
              "estimatedSales": '', //number input
              "payFrequencyId": '', /// master Drop down
              "paymentOn": '',  number input // Day Number
              "salesTaxAccntNo": '',
              "securityQuestion": '',
              "securityAnswer": '',
              "documents": [],
              "userName": "",
              "password": "",
              "operating": true, // check box
              "status": true //pass false while delete stateREG,
              "trxnStartDate":''
        }*/
        ],
        identifiers: [
          {
            _id: "", // Required in update state identifier
            marketPlace: 1, //master Data
            identifier: "",
            shopifyLink: "",
            collaboratorCode: "",
            clientKey: "",
            clientSecret: "",
            clientKeyType: "text",
            clientSecretType: "text",
          },
        ],
        logo: "",
        //"profCompleted": "Yes" // Send 'Yes' on final submission
      },
      llc_type: [],
      allMonths: [
        { name: "January", id: 1 },
        { name: "February", id: 2 },
        { name: "March", id: 3 },
        { name: "April", id: 4 },
        { name: "May", id: 5 },
        { name: "June", id: 6 },
        { name: "July", id: 7 },
        { name: "August", id: 8 },
        { name: "September", id: 9 },
        { name: "October", id: 10 },
        { name: "November", id: 11 },
        { name: "December", id: 12 },
      ],
      accTypes: [
        "Business Checking",
        "Business Savings",
        "Personal Checking",
        "Personal Savings",
        // {name:'Business Checking', id:1},
        // {name:'Business Savings', id:2}
      ],
      likeTypes: ["Yes", "No"],

      bus_structure: [],
      bus_type: [],
      nexus: [],
      platform: [],
      pay_frequency: [],
      acc_method: [],
      usstateList: [],
      clientTypeId: "password",
      clientTypeSecret: "password",
      bank: [],
      usCountry: {
        _id: 231,
        sName: "US",
        name: "United States",
        phoneCode: 1,
        curSymbol: "$",
        curCode: "USD",
        zipLen: 5,
      },
      phoneCode: null,
      phone: null,
      ClientDataloaded: false,
    };
  },
  methods: {
    formatSSN(event, owner) {
      let value = event.target.value.replace(/[^a-zA-Z0-9]/g, '');

      if (value.length > 3 && value.length <= 5) {
        value = `${value.slice(0, 3)}-${value.slice(3)}`;
      } else if (value.length > 5) {
        value = `${value.slice(0, 3)}-${value.slice(3, 5)}-${value.slice(5)}`;
      }

      owner.SSN = value;
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    isPurchaseExistingBusiness() {
      if (!this.Payload.purchaseExistingBusiness) {
        this.Payload.purchaseAgreementDocs = [];
      } else {
        // if(_.has(this.clientData ,"purchaseAgreementDocs")){
        //      this.Payload.purchaseAgreementDocs = this.clientData.purchaseAgreementDocs;
        //      this.purchaseAgreementDocs ='purchaseAgreementDocs';
        // if(this.Payload.purchaseAgreementDocs.length<=0){
        //     this.purchaseAgreementDocs ='';
        // }
        // }
      }
    },

    handleCheckboxChange() {
      // Check if the checkbox for "Others" is unchecked
      const isOthersUnchecked = !this.Payload.marketPlaceSellId.includes(6); // Assuming 6 represents "Others"

      if (isOthersUnchecked) {
        this.Payload.otherMarketPlace = ""; // Clear the otherMarketPlace field
      }
    },

    updateEmailHost(item) {
      // Payload.hostDetails
      if (_.has(item, "_id")) {
        this.Payload["emailHost"] = item["_id"];
      }
    },

    removePreviousReturnNotifyState(index) {
      if (this.Payload["previousReturnNotifyStates"].length >= 2) {
        this.Payload["previousReturnNotifyStates"].splice(index, 1);
      }
      this.findUsedStates(null);
    },
    addPreviousReturnNotifyState() {
      this.Payload["previousReturnNotifyStates"].push({
        reason: "",
        stateId: "",
        stateDetails: "",
      });
      this.findUsedStates(null);
    },
    // empPayrollStateIds empPayrollStateDetails updateEmpPayrollStates

    updateEmpPayrollStates(item) {
      this.log(item);

      this.Payload.empPayrollStateIds = [];
      if (this.Payload.empPayrollStateDetails.length > 0) {
        _.forEach(this.Payload.empPayrollStateDetails, (obj) => {
          if (this.Payload.empPayrollStateIds.indexOf(obj._id) <= -1) {
            this.Payload.empPayrollStateIds.push(obj._id);
          }
        });
      }
    },

    addsecretaryStateNumber() {
      this.Payload.secretaryStateNumbers.push({ number: "" });
    },
    removesecretaryStateNumber(index) {
      this.Payload.secretaryStateNumbers.splice(index, 1);
    },
    updatemarketPlaceSell(item) {
      this.Payload.marketPlaceSellDetails = item;
      this.Payload.marketPlaceSellId = [];
      if (_.has(this.Payload.marketPlaceSellDetails, "_id")) {
        this.Payload.marketPlaceSellId = this.Payload.marketPlaceSellDetails[
          "_id"
        ];
      }
    },
    updatesupplier(item) {
      item.typeId = null;
      if (_.has(item, "typeDetails") && _.has(item["typeDetails"], "_id")) {
        item.typeId = item.typeDetails["_id"];
      }
    },
    updateinventory(item) {
      item.typeId = null;
      if (_.has(item, "typeDetails") && _.has(item["typeDetails"], "_id")) {
        item.typeId = item.typeDetails["_id"];
      }
    },
    checkClientStatus() {
      if (
        this.checkProperty(this.clientData, "statusId") != "--" &&
        parseInt(this.checkProperty(this.clientData, "statusId")) == 2
      ) {
        return true;
      } else {
        return false;
      }
    },
    customFormatter(date) {
      //stateReg.trxnStartDate

      return moment(date).format("yyyy-MM-DD"); //('yyyy-MM-dd');
    },
    changedGlobalclient(cl) {
      if (
        cl != null &&
        _.has(cl, "_id") &&
        cl["_id"] != null &&
        cl["_id"] != "null" &&
        this.Payload.clientId &&
        this.Payload.clientId != cl["_id"]
      ) {
        this.Payload.clientId = cl["_id"];
        //this.getClientdetails();
      } else if (_.has(this.userdata, "clientDetails")) {
        this.Payload.clientId = this.userdata.clientDetails._id;
      } else if (_.has(this.$route.params, "clientId")) {
        this.Payload.clientId = this.$route.params["clientId"];
      }
      this.getClientdetails();
    },
    removeLogo() {
      this.Payload.logo = "";
      //  alert();
    },

    uploadLogo(model) {
      let temp_count = 0;
      let formData = new FormData();

      let mapper = model.map(
        (item) =>
          (item = {
            name: item.name,
            file: item.file ? item.file : null,
            path: item.url ? item.url : "",
            url: item.url ? item.url : "",
            extn: item.name.split(".").pop(),
            mimetype: item.type ? item.type : item.mimetype,
          })
      );

      //var ext = mapper[0].extn.split('.').pop();

      if (mapper.length > 0) {
        mapper.forEach((doc, index) => {
          formData.append("files", doc.file);
          formData.append("secureType", "public");
          this.$store.dispatch("uploadS3File", formData).then((response) => {
            temp_count++;
            response.data.result.forEach((urlGenerated) => {
              if (_.has(urlGenerated, "path") && !_.has(urlGenerated, "url")) {
                urlGenerated["url"] = urlGenerated["path"];
              }
              if (_.has(urlGenerated, "url") && !_.has(urlGenerated, "path")) {
                urlGenerated["path"] = urlGenerated["url"];
              }

              this.Payload["logo"] = _.cloneDeep(urlGenerated.path);

              if (temp_count >= mapper.length) {
                this.documents = [];
              }

              doc.url = urlGenerated;
              delete doc.file;
              mapper[index] = doc;
            });
          });
        });
        model.splice(0, mapper.length, ...mapper);
      }
    },
    isOperating(e, index) {
      //  console.log(e);
      if (e.target.checked) {
        this.Payload.stateReg[index].operating = true;
      } else {
        this.Payload.stateReg[index].operating = false;
      }
    },
    finalValidation() {
      let keys = Object.keys(this.blocks);

      let tempArr = [];
      _.forEach(this.blocks, (item, ind) => {
        console.log(item);
        if (this.show_block == ind + 1) {
          let active_blok = this.blocks[keys[ind]];
          try {
            if (this.$refs[active_blok.validator]) {
              const valid = this.$refs[active_blok.validator].validate();
              this.blocks[keys[ind]].formIsValid = valid;
            }
          } catch (err) {
            this.blocks[keys[ind]].formIsValid = false;
            console.log(err);
          }
        }
      });

      _.forEach(this.blocks, (item, ind) => {
        this.log(ind);
        //alert(JSON.stringify(item) +"---------------")
        if (item.formIsValid) {
          tempArr.push(true);
        } else {
          tempArr = [];
        }
      });

      if (tempArr.length >= keys.length) {
        return true;
      } else {
        return false;
      }
    },
    deleteRegstate(index, itemModel) {
      this.log(itemModel);
      // Payload.stateRegs remove from this list

      // alert(index);
      this.Payload.stateRegs = _.filter(this.Payload.stateRegs, (state) => {
        return itemModel.stateId != state._id;
      });

      itemModel.status = false;
      itemModel.show = false;
      this.Payload.stateReg[index]["status"] = false;
      this.Payload.stateReg[index]["show"] = false;
    },
    hideCredentials(index, itemModel) {
      this.log(itemModel);
      this.Payload.stateReg[index]["hasCredentials"] = true;
    },
    getCredentials(index, itemModel) {
      this.Payload.stateReg[index]["hasCredentials"] = true;
      this.Payload.stateReg[index]["userName"] = "*****";
      this.Payload.stateReg[index]["password"] = "*****";
      this.Payload.stateReg[index]["hasCredentials"] = false;
      if (
        _.has(itemModel, "_id") &&
        itemModel._id != "" &&
        itemModel._id != undefined &&
        itemModel._id != null
      ) {
        this.$store
          .dispatch("getCredentials", {
            clientId: this.Payload.clientId,
            matrixId: itemModel._id,
          })
          .then((res) => {
            // alert(JSON.stringify(res));
            itemModel.userName = _.cloneDeep(res.userName);
            itemModel.password = _.cloneDeep(res.password);
            itemModel.hasCredentials = false;
            this.Payload.stateReg[index]["userName"] = _.cloneDeep(
              res.userName
            );
            this.Payload.stateReg[index]["password"] = _.cloneDeep(
              res.password
            );
            this.Payload.stateReg[index]["hasCredentials"] = false;
            this.Payload.stateReg[index]["hasCredentials"] = true;
            this.Payload.stateReg[index]["hasCredentials"] = false;
            //alert(JSON.stringify(this.Payload.stateReg[index]));

            // alert(this.Payload.stateReg[index]['userName'] );
          })
          .catch((err) => {
            this.log(err);
            itemModel.hasCredentials = false;
            this.Payload.stateReg[index]["userName"] = "";
            this.Payload.stateReg[index]["password"] = "";
            this.Payload.stateReg[index]["hasCredentials"] = false;
            this.Payload.stateReg[index]["hasCredentials"] = true;
            this.Payload.stateReg[index]["hasCredentials"] = false;
          });
      } else {
        itemModel.hasCredentials = false;
        this.Payload.stateReg[index]["userName"] = "";
        this.Payload.stateReg[index]["password"] = "";
        this.Payload.stateReg[index]["hasCredentials"] = false;
      }
    },
    showToggle(index, itemModel) {
      this.log(index + " showToggle INDEX");
      this.log(itemModel);
      /*
          if(itemModel.show){
              itemModel.show =false;
          }else{
              itemModel.show =true;
          }
           */

      if (this.Payload.stateReg.length > 0) {
        let tempStates = _.cloneDeep(this.Payload.stateReg);
        this.Payload.stateReg = tempStates;

        _.forEach(this.Payload.stateReg, (item, ind) => {
          if (index == ind) {
            if (this.Payload.stateReg[ind]["show"]) {
              //  itemModel.show =false;
              // item.show =false;
              this.Payload.stateReg[ind]["show"] = false;
            } else {
              // itemModel.show =true;
              // item.show =true;
              this.Payload.stateReg[ind]["show"] = true;
            }
          }
        });
      }
    },
    addIdentifiers() {
      this.ClientDataloaded = false;
      this.Payload["identifiers"].push({
        _id: "", // Required in update state identifier
        marketPlace: 1, //master Data
        identifier: "",
        shopifyLink: "",
        collaboratorCode: "",
        clientKey: "",
        clientSecret: "",
        clientKeyType: "text",
        clientSecretType: "text",
      });
      this.ClientDataloaded = true;
    },
    removeIdentifiers(index) {
      this.Payload["identifiers"].splice(index, 1);
    },
    toggleTextType(index, type = "clientKeyType") {
      /*
          clientSecretType
          clientKeyType
          */
      let tempIdentifiers = _.cloneDeep(this.Payload["identifiers"]);
      this.Payload["identifiers"] = [];
      _.forEach(tempIdentifiers, (item, itemIndex) => {
        if (itemIndex == index) {
          if (_.has(item, type)) {
            if (item[type] == "text") {
              item[type] = "password";
            } else {
              item[type] = "text";
            }
          } else {
            item = Object.assign(item, { type: "password" });
          }
        }
        this.Payload["identifiers"].push(item);
      });
    },
    updateOwnerLicensedState(data) {
      //licensedState indexKey licensedState
      //alert(JSON.stringify(data));
      if (
        _.has(data, "index") &&
        _.has(data, "payLoadKey") &&
        _.has(data, "stateDetails") &&
        _.has(data, "indexKey")
      ) {
        if (_.has(data["stateDetails"], "_id")) {
          //alert(JSON.stringify(data['stateDetails']['_id']));
          this.Payload[data["payLoadKey"]][data["index"]][data["indexKey"]] =
            data["stateDetails"]["_id"];
        }

        //licensedState
      }
    },
    updateotherBusState(item) {
      this.log(item);

      this.Payload.otherBusStateIds = [];
      if (this.Payload.otherBusStates.length > 0) {
        _.forEach(this.Payload.otherBusStates, (obj) => {
          if (this.Payload.otherBusStateIds.indexOf(obj._id) <= -1) {
            this.Payload.otherBusStateIds.push(obj._id);
          }
        });
      }
    },

    updateSellingPlatforms(item) {
      this.log(item);
      //"sellingPlatformIds": [], /// master
      //platformDetails:null,
      this.Payload.sellingPlatformIds = [];
      if (this.Payload.platformDetails.length > 0) {
        _.forEach(this.Payload.platformDetails, (obj) => {
          if (this.Payload.sellingPlatformIds.indexOf(obj._id) <= -1) {
            this.Payload.sellingPlatformIds.push(obj._id);
          }
        });
      }
    },
    updateNexusTypeDetails(item) {
      if (_.has(item, "_id")) {
        this.Payload.nexusTypeId = item._id;
      } else {
        this.Payload.nexusTypeId = "";
      }
    },

    updatetaxBegginingPeriodDetails(item) {
      if (_.has(item, "id")) {
        this.Payload.taxBegginingPeriod = item.id;
      } else {
        this.Payload.taxBegginingPeriod = "";
      }
    },
    updateaccTypeDetails(item) {
      if (_.has(item, "id")) {
        this.Payload.accTypes = item.id;
      } else {
        this.Payload.accTypes = "";
      }
    },

    updateBusStructure(item) {
      if (item && _.has(item, "_id")) {
        this.Payload.busStructureId = item._id;
      } else {
        this.Payload.busStructureDetails = null;
        this.Payload.busStructureDetailsOther = null;
        this.Payload.busStructureId = "";
      }
    },
    formatEinInput() {    
      // Get the raw input value
        let inputValue = this.Payload.EIN;

      // Remove any non-numeric characters except "-"
      inputValue = inputValue.replace(/[^0-9]/g, "");

      // Format the input as EIN (XX-XXXXXXX)
      if (inputValue.length > 2) {
        this.Payload.EIN = `${inputValue.slice(0, 2)}-${inputValue.slice(2, 10)}`;
      } else {
        this.Payload.EIN = inputValue; // Keep the first two characters as-is
      }
    },
    // formatEinInput() {    
    //   let inputValue = this.Payload.EIN;

    //   // Remove any non-numeric characters
    //   inputValue = inputValue.replace(/[^0-9]/g, "");

    //   // Check if the input matches the EIN format (9 digits, with a hyphen after the second digit)
    //   if (inputValue.match(/^\d{2}-\d{7}$/)) {
    //     // Valid EIN
    //     this.Payload.EIN = inputValue;
    //   } else {
    //     // Invalid EIN
    //     if (inputValue.length > 2) {
    //       this.Payload.EIN = `${inputValue.slice(0, 2)}-${inputValue.slice(2)}`;
    //     }
    //   }
    // },
    updatellcTypes(item) {
      if (item && item.length > 0) {
        this.Payload.llcTypeIds = item.map((llc) => {
          return llc["_id"];
        });
      } else {
        this.Payload.llcTypeDetails = [];
        this.Payload.llcTypeIds = [];
      }
    },
    updateIncorporatedState(item) {
      this.Payload.articlesOfOrgAndDriverLiecenses = [];
      if (item && _.has(item, "_id")) {
        this.Payload.incorporatedState = item._id;
      } else {
        this.Payload.incorporatedStateDetails = null;
        this.Payload.incorporatedState = "";
      }
    },

    updatellcTypes2(type) {
      this.Payload.llcTypeIds = [];
      //   alert(JSON.stringify(type))
      _.forEach(type, (item) => {
        this.Payload.llcTypeIds.push(item["_id"]);
      });
    },
    removeDoc(index, model, type = "") {
      model.splice(index, 1);
      if (type == "purchaseAgreementDocs") {
        if (this.Payload.purchaseAgreementDocs.length == 0) {
          this.purchaseAgreementDocs = "";
        } else {
          this.purchaseAgreementDocs = "purchaseAgreementDocs";
        }
      }
    },
    onUploadComplete(data) {
      if (
        _.has(data, "finalDocuments") &&
        data["finalDocuments"].length > 0 &&
        _.has(data, "indexValue") &&
        _.has(data, "payLoadKey") &&
        _.has(data, "payLoadDocumentsKey")
      ) {
        if (_.has(data, "allowMultiple") && data["allowMultiple"] == true) {
          _.forEach(data["finalDocuments"], (item) => {
            if (data["takeIndex"]) {
              //alert(JSON.stringify( this.Payload[data['payLoadKey']][data['indexValue']][data['payLoadDocumentsKey']]))
              this.Payload[data["payLoadKey"]][data["indexValue"]][
                data["payLoadDocumentsKey"]
              ].push(item);
            } else {
              if (data["payLoadDocumentsKey"] != "") {
                this.Payload[data["payLoadKey"]][
                  data["payLoadDocumentsKey"]
                ].push(item);
              } else {
                if (data["payLoadKey"] == "purchaseAgreementDocs") {
                  this.purchaseAgreementDocs = "purchaseAgreementDocs";
                }
                this.Payload[data["payLoadKey"]].push(item);
              }
            }
          });
        } else {
          if (data["payLoadKey"] == "logo") {
            // alert(data['finalDocuments'][0]['url']);
            this.Payload["logo"] = data["finalDocuments"][0]["url"];
          } else if (data["takeIndex"] && data["payLoadDocumentsKey"] != "") {
            this.Payload[data["payLoadKey"]][data["indexValue"]][
              data["payLoadDocumentsKey"]
            ] = data["finalDocuments"][0];
          } else if (!data["takeIndex"] && data["payLoadDocumentsKey"] == "") {
            this.Payload[data["payLoadKey"]] = data["finalDocuments"][0];
          }
        }
      }
    },
    addstateReg(item) {
      this.log(item);
      if (this.Payload.stateRegs.length > 0) {
        this.Payload.stateReg = [];
        // alert(JSON.stringify(this.clientData.stateReg));
        let dt = _.cloneDeep(this.Payload.stateRegs);
        _.forEach(dt, (stateReg) => {
          let temp_bank = {
            _id: "", // Required in update state registration
            statename: stateReg.name,
            name: stateReg.name,
            stateDetails: { name: stateReg.name, _id: stateReg._id },
            stateId: stateReg._id,
            estimatedSales: "",
            payFrequencyId: "", /// master
            paymentOn: "", // Day Number
            salesTaxAccntNo: "",
            securityQuestion: "",
            securityAnswer: "",
            documents: [],
            userName: "",
            password: "",
            operating: true, // check box
            status: true, //pass false while delete stateREG,
            show: true,
            hasCredentials: false,
            trxnStartDate: "",
          };
          //   let isExists = _.find(this.Payload.stateReg ,{ "stateId":stateReg._id });
          let dbData = _.find(this.clientData.stateReg, {
            stateId: stateReg._id,
          });

          if (!dbData) {
            this.Payload.stateReg.splice(0, 0, temp_bank);
            //this.Payload.stateReg.push(temp_bank);
          } else {
            dbData.show = false;
            dbData.status = true;
            //this.Payload.stateReg.splic(0,0 ,dbData);
            this.Payload.stateReg.push(dbData);
          }
        });
      } else {
        this.Payload.stateReg = [];
      }

      // remove functionality
      // this.Payload.stateReg // this.clientData.stateReg
      if (_.has(this.clientData, "stateReg")) {
        _.forEach(this.clientData.stateReg, (dbStateReg) => {
          let isExists = _.find(this.Payload.stateReg, {
            stateId: dbStateReg.stateId,
          });
          if (!isExists) {
            dbStateReg.show = false;
            dbStateReg.status = false;
            this.Payload.stateReg.push(dbStateReg);
          }
        });
      }

      setTimeout(() => {
        if (_.has(this.$refs, "statesalreadyregistered")) {
          try {
            this.log(this.$refs["statesalreadyregistered"]);
            console.log(this.$refs["statesalreadyregistered"]);
            this.$refs["statesalreadyregistered"].reset();
          } catch (err) {
            this.log(err);
          }
        }

        _.forEach(this.Payload.stateReg, (state, index) => {
          state.show = false;
          if (index <= 0) {
            setTimeout(() => {
              _.forEach(this.Payload.stateReg, (item, ind) => {
                if (ind <= 0) {
                  item.show = true;
                }
              });
            });
          }
        });
      }, 2000);
    },
    removestateReg(indx) {
      if (
        _.has(this.Payload.stateReg[indx], "_id") &&
        this.Payload.stateReg[indx]["_id"] != ""
      ) {
        this.Payload.stateReg[indx]["_id"]["status"] = false;
      } else {
        this.Payload.stateReg.splice(indx, 1);
        this.Payload.stateRegs = this.Payload.banks;
      }
    },

    addOwner() {
      let sp = {
        name: "",
        title: "",
        titleEffDate: "",
        titleExpDate: "",
        address: {
          addr1: "",
          addr2: "",
          stateId: null,
          cityId: null,
          zip: "",
          cntryId: 231,
        },
        phone: "",
        phoneCode: "",
        email: "",
        dateOfBirth: "",
        SSN: "",
        sharePercent: "",
        driverLicenseNo: "",
        driverLicenseDocs: [],
        licensedState: "", // satateId of us Country
      };
      this.Payload.owners.push(sp);
    },
    removeOwner(index) {
      this.Payload.owners.splice(index, 1);
    },
    addSuplier() {
      let sp = {
        typeId: null,
        typeDetails: "",
        name: "",
        email: "",
        address: {
          addr1: "",
          addr2: "",
          stateId: null,
          cityId: null,
          zip: "",
          cntryId: null,
        },
        phone: "",
        phoneCode: "",
      };
      this.Payload.supplier.push(sp);
    },
    addInventory() {
      let sp = {
        typeId: null,
        typeDetails: "",
        otherInventoryLocation: "",
        address: {
          addr1: "",
          addr2: "",
          stateId: null,
          cityId: null,
          zip: "",
          cntryId: null,
        },
      };
      this.Payload.inventoryLocations.push(sp);
    },
    removeSuplier(index) {
      // alert(index);
      this.Payload.supplier.splice(index, 1);
    },
    removeInventory(index) {
      // alert(index);
      this.Payload.inventoryLocations.splice(index, 1);
    },
    changedbankState(item, index) {
      alert(JSON.stringify(item) + "  -- " + index);
    },
    addBank(item) {
      this.log(item);
      let tempBanks = _.cloneDeep(this.Payload.banks);
      if (this.Payload.bankDetails.length > 0) {
        this.Payload.banks = [];
        _.forEach(this.Payload.bankDetails, (bank) => {
          let temp_bank = {
            id: bank._id, /// master
            otherName: bank.otherName,
            accType: "",
            stateId: bank.stateId,
            stateDetails: bank.stateDetails,
            cityId: bank.cityId,
            cityDetails: bank.cityDetails,
            stateList: bank.stateList,
            routingNo: bank.routingNo,
            accNo: bank.accNo,
            accountName: "",
            name: bank.name,
            phone: null,
            phoneCode: "+1",
            address: {
              addr1: "",
              addr2: "",
              stateId: null,
              cityId: null,
              zip: "",
              cntryId: null,
            },
          };
          if (_.has(bank, "accountName")) {
            temp_bank["accountName"] = bank["accountName"];
          }
          let tmp = _.find(this.Payload.banks, { id: bank._id });
          if (!tmp) {
            if (this.clientData != null && _.has(this.clientData, "banks")) {
              let dbBank = _.find(this.clientData["banks"], { id: bank._id });
              //alert(JSON.stringify(bank._id));
              if (dbBank) {
                //this.loa
                dbBank["name"] = bank.name;
                if (!_.has(dbBank, "accountName")) {
                  dbBank = Object.assign(dbBank, { accountName: "" });
                }
                this.Payload.banks.push(dbBank);
              } else {
                let selectedBnk = _.find(tempBanks, { name: bank.name });
                if (selectedBnk) {
                  temp_bank = {
                    id: selectedBnk._id, /// master
                    otherName: selectedBnk.otherName,
                    stateId: selectedBnk.stateId,
                    stateDetails: selectedBnk.stateDetails,
                    cityId: selectedBnk.cityId,
                    cityDetails: selectedBnk.cityDetails,
                    stateList: selectedBnk.stateList,
                    routingNo: selectedBnk.routingNo,
                    accNo: selectedBnk.accNo,
                    name: selectedBnk.name,
                    accountName: "",
                  };

                  if (_.has(selectedBnk, "accountName")) {
                    temp_bank["accountName"] = selectedBnk["accountName"];
                  }
                }

                this.Payload.banks.push(temp_bank);
              }
            } else {
              this.Payload.banks.push(temp_bank);
            }
          }
        });
      } else {
        this.Payload.banks = [];
      }
    },
    removeBank(indx) {
      this.Payload.banks.splice(indx, 1);
      this.Payload.bankDetails = this.Payload.banks;
    },
    selectedbType(method) {
      console.log(method);
      this.Payload.busTypeIds = [];
      // this.Payload.businessTypeDetails =method;
      if (this.Payload.businessTypeDetails) {
        _.forEach(this.Payload.businessTypeDetails, (item) => {
          this.Payload.busTypeIds.push(item._id);
        });
      }
    },
    selectedAccount(method) {
      _.forEach(this.acc_method, (obj, index) => {
        this.acc_method[index]["selected"] = false;
        if (obj["_id"] == method["_id"]) {
          this.Payload.accMethodId = method["_id"];
          this.acc_method[index]["selected"] = true;
        }
      });

      this.Payload.accMethodDetails = method;
      this.Payload.accMethodId = method._id;
    },
    autoSave() {
      let pload = _.cloneDeep(this.Payload);

      this.$store.dispatch("saveQuestionnaire", pload);
    },
    async getNextForm() {
      let keys = Object.keys(this.blocks);

      if (this.show_block <= keys.length) {
        let ind = this.show_block - 1;
        if (this.show_block == 1) {
          ind = 0;
        }
        if (this.show_block == keys.length) {
          ind = keys.length - 1;
        }
        let active_blok = this.blocks[keys[ind]];
        const valid = await this.$refs[active_blok.validator].validate();
        this.blocks[keys[ind]].formIsValid = valid;

        if (this.show_block != keys.length && valid) {
          this.show_block = this.show_block + 1;
          let type = "";
          if (this.show_block > 4) {
            type = "Yes";
          }

          if (this.clientData.statusId >= 1) {
            this.saveQuestionnaire(type);
          }

          this.togleBlock();
        }
        if (!valid) {
          this.scrollWindow();
        }
      } else {
        this.show_block = 1;
      }
    },
    scrollWindow() {
      const $ = JQuery;

      if ($(".error-msg").length) {
        $("html, body").animate(
          {
            scrollTop: $(".error-msg")
              .first()
              .offset().top,
          },
          2000
        );
      }
      //  alert($('.error-msg').first().html())

      //  const firstField = Object.keys(this.errors.collect())[0];
      // const ele = $('[name=' + firstField + ']').parents('.vx-col');
      //  $('html, body').animate({ scrollTop: ele.offset().top }, 2000);
    },
    togleBlock(callFromMenu = false) {
      this.log(callFromMenu);
      let index = 1;

      if (this.Payload.stateReg.length > 0) {
        _.forEach(this.Payload.stateReg, (item, index) => {
          console.log("first itration ===" + index);
          item["show"] = true;
          this.Payload.stateReg[index]["show"] = true;
        });
      }

      _.forEach(this.blocks, (item, key) => {
        console.log("BLOCK ===" + key);
        if (this.show_block == index) {
          this.blocks[key].active = false;
          this.blocks[key].active = true;
          item.active = false;
          item.active = true;
          let validator = this.blocks[key].validator;

          try {
            if (_.has(this.$refs, validator)) {
              this.$refs[validator].reset();
              setTimeout(() => {
                document.documentElement.scrollTop = 0;
              }, 10);
            }
          } catch (err) {
            this.log(err);
          }
        } else {
          this.blocks[key].active = false;
          item.active = false;
        }
        index = index + 1;
      });

      if (this.Payload.stateReg.length > 0) {
        _.forEach(this.Payload.stateReg, (item, index) => {
          console.log("second itration ===" + index);
          item["show"] = false;
          this.Payload.stateReg[index]["show"] = false;
        });
      }
    },
    saveQuestionnaire(profCompleted = "") {
      let valid = true;

      let pload = _.cloneDeep(this.Payload);
      if (profCompleted != "" && this.clientData.statusId < 2) {
        pload["profCompleted"] = "Yes";
        valid = this.finalValidation();
      }
      if (pload["stateReg"].length > 0) {
        _.forEach(pload["stateReg"], (state, index) => {
          if (_.has(state, "trxnStartDate") && state["trxnStartDate"] != null) {
            let date = state["trxnStartDate"];
            this.Payload["stateReg"][index]["trxnStartDate"] = moment(
              date
            ).format("yyyy-MM-DD");
            pload["stateReg"][index]["trxnStartDate"] = moment(date).format(
              "yyyy-MM-DD"
            );
          } else {
            this.Payload["stateReg"][index]["trxnStartDate"] = "";
            pload["stateReg"][index]["trxnStartDate"] = "";
          }
        });
      }
      //this.payLoad['stateRegs'][index]['trxnStartDate'] =moment(date).format('yyyy-MM-DD');
      pload["previousReturnNotifyStates"] = [];
      if (this.Payload.previousReturnNotifyStates.length > 0) {
        _.forEach(this.Payload.previousReturnNotifyStates, (item) => {
          if (
            _.has(item, "stateDetails") &&
            _.has(item["stateDetails"], "_id")
          ) {
            item["stateId"] = item["stateDetails"]["_id"];
            pload["previousReturnNotifyStates"].push(item);
          }
        });
      }

      if (valid) {
        this.$store
          .dispatch("saveQuestionnaire", pload, true)
          .then((res) => {
            this.notify({
              message: res.data.result.message,
              title: "Success",
              type: "success",
            });
            this.getClientdetails();

            if (profCompleted == "Yes" || this.show_block > 4) {
              this.$router.push("/client-list");
            }
          })
          .catch((err) => {
            // this.addClientModel =false;
            this.notify({ message: err, title: "Error", type: "error" });
          });
      } else {
        this.scrollWindow();
      }
    },
    updatePhoneNumber(item) {
      console.log(JSON.stringify(item));

      if (item.isValid) {
        this.Payload.busContact.phoneCode = item.countryCallingCode;
        this.Payload.busContact.phone = item.nationalNumber;
      }
    },
    updatePhoneNumber1(item) {
      console.log(JSON.stringify(item));

      if (item.isValid) {
        this.Payload.busphoneCode = item.countryCallingCode;
        this.Payload.busPhone = item.nationalNumber;
      }
    },
    phoneNumberupdate(item) {
      if (item.isValid) {
        this.phoneCode = item.countryCallingCode;
        this.phone = item.nationalNumber;
      } else {
        this.phoneCode = null;
        this.phone = null;
      }
    },
    updatePhone(index) {
      if (this.phoneCode != null && this.phone != null) {
        //  alert(index);
        this.Payload.supplier[index]["phoneCode"] = this.phoneCode;
        this.Payload.supplier[index]["phone"] = this.phone;
        //model.phoneCode = this.phoneCode;
        //model.phone =this.phone;
      }
    },
    updatePhc(data) {
      if (
        _.has(data, "index") &&
        _.has(data, "phone") &&
        _.has(data, "phoneCode") &&
        _.has(data, "payLoadKey")
      ) {
        this.Payload[data["payLoadKey"]][data["index"]].phone = data["phone"];
        this.Payload[data["payLoadKey"]][data["index"]].phoneCode =
          data["phoneCode"];
      }
    },
    getUsStatesList() {
      let payLoad = {
        matcher: {
          searchString: "",
          //"cntryId": 101,
          //"stateId": 665,
          cntryIds: [231], // Requied for "state"
          //"stateIds": [665], // Requied for "city"
        },
        page: 1,
        perpage: 10000000000,
        category: "state", // acc_method, bank, user_status, user_role, user_list, country, state, platform, business, bus_type, notes_category, notes_status, client_status, nexus, pay_frequency
      };
      this.$store.dispatch("getMaterData", payLoad).then((res) => {
        this.usstateList = res.list;
        this.filteredUsstateList = this.usstateList;
      });
    },

    getMaterData(category) {
      let payLoad = {
        matcher: {
          searchString: "",
          //"cntryId": 101,
          //"stateId": 665,
          //"cntryIds": [38], // Requied for "state"
          //"stateIds": [665], // Requied for "city"
        },
        page: 1,
        perpage: 10000000000,
        category: category, // acc_method, bank, user_status, user_role, user_list, country, state, platform, business, bus_type, notes_category, notes_status, client_status, nexus, pay_frequency
      };
      this.$store
        .dispatch("getMaterData", payLoad)
        .then((res) => {
          let dt = res.list;
          //if(category =="llc_type"){
          _.forEach(dt, (obj, index) => {
            dt[index]["selected"] = false;
            this[category].push(dt[index]);
          });

          //  }else{
          //     this[category] = dt;
          //  }
        })
        .catch((err) => {
          this.log(err);
        });
    },
    getClientdetails() {
      this.ClientDataloaded = false;
      this.$store
        .dispatch("getClientdetails", { clientId: this.Payload.clientId })
        .then((res) => {
          this.clientData = res;
          //this.Payload =res;
          this.Payload["busContact"]["fName"] = this.userdata["fName"];
          this.Payload["busContact"]["fName"] = this.userdata["fName"];
          this.Payload["busContact"]["phone"] = this.userdata["phone"];
          this.Payload["busContact"]["phoneCode"] = this.userdata["phoneCode"];
          this.Payload["busContact"]["website"] = this.userdata["website"];
          this.processData();

          this.togleBlock();
          this.ClientDataloaded = true;
        })
        .catch((err) => {
          this.log(err);
          this.ClientDataloaded = true;
        });
    },
    processData() {
      //Legal Information
      this.ClientDataloaded = false;
      this.Payload.busAddress = this.clientData.busAddress;
      this.Payload.mailingAddress = this.clientData.mailingAddress;

      //check mailingAddress As Same  busAddress
      //"addr1": "dfgdf", "addr2": "dfgdg", "stateId": 2, "cityId": null, "zip": "", "cntryId": ,

      if (
        _.has(this.clientData.busAddress, "addr2") &&
        _.has(this.clientData.mailingAddress, "addr2") &&
        this.clientData.busAddress["addr2"] ===
          this.clientData.mailingAddress["addr2"] &&
        _.has(this.clientData.busAddress, "stateId") &&
        _.has(this.clientData.mailingAddress, "stateId") &&
        this.clientData.busAddress["stateId"] ===
          this.clientData.mailingAddress["stateId"] &&
        _.has(this.clientData.busAddress, "cityId") &&
        _.has(this.clientData.mailingAddress, "cityId") &&
        this.clientData.busAddress["cityId"] ===
          this.clientData.mailingAddress["cityId"] &&
        _.has(this.clientData.busAddress, "cntryId") &&
        _.has(this.clientData.mailingAddress, "cntryId") &&
        this.clientData.busAddress["cntryId"] ===
          this.clientData.mailingAddress["cntryId"] &&
        _.has(this.clientData.busAddress, "zip") &&
        _.has(this.clientData.mailingAddress, "zip") &&
        this.clientData.busAddress["zip"] ===
          this.clientData.mailingAddress["zip"]
      ) {
        this.mailingAddressAsSame = true;
        // alert();
      } else if (
        _.has(this.clientData.mailingAddress, "addr2") &&
          this.clientData.mailingAddress["addr2"] === Config.taxheroMailingAddress.addr2 &&
        _.has(this.clientData.mailingAddress, "stateId") &&
          this.clientData.mailingAddress["stateId"] === Config.taxheroMailingAddress.stateId &&
        _.has(this.clientData.mailingAddress, "cityId") &&
          this.clientData.mailingAddress["cityId"] === Config.taxheroMailingAddress.cityId &&
        _.has(this.clientData.mailingAddress, "cntryId") &&
          this.clientData.mailingAddress["cntryId"] === Config.taxheroMailingAddress.cntryId &&
        _.has(this.clientData.mailingAddress, "zip") &&
          this.clientData.mailingAddress["zip"] === Config.taxheroMailingAddress.zip
      ) {
        this.mailingAddressAsSame = null;
      } else {
        this.mailingAddressAsSame = false;
      }

      this.Payload.taxRecordLocation = this.clientData.taxRecordLocation;
      this.Payload.busName = this.clientData.busName;
      this.Payload.tradeName = this.clientData.tradeName;
      this.Payload.logo = this.clientData.logo;

      //Business Information
      this.Payload.busContact = this.clientData.busContact;
      this.Payload.fedaralEmpId = this.clientData.fedaralEmpId;

      if (
        _.has(this.clientData, "busFormatedOn") &&
        this.clientData["busFormatedOn"] != null &&
        this.clientData["busFormatedOn"] != "" &&
        this.clientData["busFormatedOn"] != undefined
      ) {
        //alert(this.clientData.busFormatedOn)
        this.Payload.busFormatedOn = moment(
          this.clientData.busFormatedOn
        ).format("YYYY-MM-DD");
      }

      this.Payload.busStructureId = this.clientData.busStructureId;
      //  this.Payload.busStructureDetails  = this.clientData.busStructureDetails;
      if (_.has(this.clientData, "busStructureDetails")) {
        let busStructureDetails = _.cloneDeep(
          this.clientData.busStructureDetails
        );
        this.Payload.busStructureDetails = {
          _id: busStructureDetails["_id"],
          name: busStructureDetails["name"],
          selected: false,
        };
        if (busStructureDetails["name"] === "Other") {
          this.Payload.busStructureDetailsOther = this.clientData.busStructureDetailsOther;
        }
      }
      if (_.has(this.clientData, "llcTypeDetails")) {
        this.Payload.llcTypeDetails = _.cloneDeep(
          this.clientData.llcTypeDetails
        );
      }

      //emailHost:'',
      //hostDetails:null,

      if (_.has(this.clientData, "emailHost")) {
        this.Payload.emailHost = _.cloneDeep(this.clientData.emailHost);
      }

      if (_.has(this.clientData, "hostDetails")) {
        this.Payload.hostDetails = _.cloneDeep(this.clientData.hostDetails);
      }

      if (_.has(this.clientData, "platformHosted")) {
        this.Payload.platformHosted = this.clientData.platformHosted;
      }

      if (_.has(this.clientData, "customPlatformHosted")) {
        this.Payload.customPlatformHosted = this.clientData.customPlatformHosted;
      }

      if (_.has(this.clientData, "emailPlatformHosted")) {
        this.Payload.emailPlatformHosted = this.clientData.emailPlatformHosted;
      }

      if (_.has(this.clientData, "passwordPlatformHosted")) {
        this.Payload.passwordPlatformHosted = this.clientData.passwordPlatformHosted;
      }

      if (_.has(this.clientData, "confirmedPlatformHosted")) {
        this.Payload.confirmedPlatformHosted = this.clientData.confirmedPlatformHosted;
      }

      //  this.Payload.llcTypeIds = this.clientData.llcTypeIds;
      //  this.Payload.llcTypeDetails = this.clientData.llcTypeDetails

      this.Payload.secretaryStateNumbers = [{ number: "" }];
      if (
        _.has(this.clientData, "secretaryStateNumbers") &&
        this.clientData["secretaryStateNumbers"].length > 0
      ) {
        this.Payload.secretaryStateNumbers = this.clientData.secretaryStateNumbers;
      }

      this.Payload.previousReturnNotifyChanges = false;
      if (_.has(this.clientData, "previousReturnNotifyChanges")) {
        this.Payload.previousReturnNotifyChanges = this.clientData.previousReturnNotifyChanges;
      }

      if (_.has(this.clientData, "incorporatedState")) {
        this.Payload.incorporatedState = this.clientData.incorporatedState;
        this.Payload.incorporatedStateDetails = _.find(this.usstateList, {
          _id: this.Payload.incorporatedState,
        });

        //alert(this.Payload.incorporatedStateDetails);
        if (this.Payload.incorporatedStateDetails)
          this.updateIncorporatedState(this.Payload.incorporatedStateDetails);
      }

      if (_.has(this.clientData, "articlesOfOrgAndDriverLiecenses")) {
        this.Payload.articlesOfOrgAndDriverLiecenses = this.clientData.articlesOfOrgAndDriverLiecenses;
      }
      if (_.has(this.clientData, "capTableDocs")) {
        this.Payload.capTableDocs = this.clientData.capTableDocs;
      }
      if (_.has(this.clientData, "industryIds")) {
        this.Payload.industryIds = this.clientData.industryIds;
      }
      //Payload.llcTypeIds
      if (_.has(this.clientData, "llcTypeIds")) {
        this.Payload.llcTypeIds = this.clientData.llcTypeIds;
        _.forEach(this.llc_type, (obj) => {
          if (this.Payload.llcTypeIds.indexOf(obj._id) > -1) {
            obj.selected = true;
          }
        });
      }

      if (_.has(this.clientData, "ownersMorethan20Percent")) {
        this.Payload.ownersMorethan20Percent = this.clientData[
          "ownersMorethan20Percent"
        ];
      }
      //accMethodDetails // accMethodId
      if (_.has(this.clientData, "accMethodDetails")) {
        this.Payload.accMethodDetails = this.clientData["accMethodDetails"];
        this.selectedAccount(this.clientData["accMethodDetails"]);
      }

      if (
        _.has(this.clientData, "accClosedOn") &&
        this.clientData["accClosedOn"]
      ) {
        //alert(this.clientData['accClosedOn'] +" ---- ");
        this.Payload.accClosedOn = moment(
          this.clientData["accClosedOn"]
        ).format("YYYY-MM-DD");
      }

      if (_.has(this.clientData, "nexusTypeDetails")) {
        this.Payload.nexusTypeDetails = this.clientData["nexusTypeDetails"];
        this.updateNexusTypeDetails(this.Payload.nexusTypeDetails);
      }
      //previousFilingEndDate
      if (
        _.has(this.clientData, "previousFilingEndDate") &&
        this.clientData["previousFilingEndDate"]
      ) {
        //alert(this.clientData['accClosedOn'] +" ---- ");
        this.Payload.previousFilingEndDate = moment(
          this.clientData["previousFilingEndDate"]
        ).format("YYYY-MM-DD");
      }

      if (_.has(this.clientData, "taxBegginingPeriod")) {
        this.Payload.taxBegginingPeriod = this.clientData["taxBegginingPeriod"];
        this.Payload["taxBegginingPeriodDetails"] = _.find(this.allMonths, {
          id: this.Payload.taxBegginingPeriod,
        });
      }
      if (_.has(this.clientData, "wouldYouLikeTaxHero")) {
        this.Payload.wouldYouLikeTaxHero = this.clientData[
          "wouldYouLikeTaxHero"
        ];
      }

      if (
        _.has(this.clientData, "previousReturnNotifyStates") &&
        this.clientData["previousReturnNotifyStates"].length > 0
      ) {
        this.Payload.previousReturnNotifyStates = this.clientData[
          "previousReturnNotifyStates"
        ];
        _.forEach(this.Payload.previousReturnNotifyStates, (item) => {
          if (_.has(item, "stateId")) {
            let state =
              _.find(this.clientData["previousReturnNotifyStateDetails"], {
                _id: item["stateId"],
              }) || null;
            item["stateDetails"] = state;
          } else {
            item["stateId"] = "";
            item["stateDetails"] = "";
          }
        });
      }

      if (_.has(this.clientData, "EIN")) {
        this.Payload.EIN = this.clientData["EIN"];
      }
      if (_.has(this.clientData, "busEmail")) {
        this.Payload.busEmail = this.clientData["busEmail"];
      }

      if (_.has(this.clientData, "busPhone")) {
        this.Payload.busPhone = this.clientData["busPhone"];
      }

      //itemDescription
      if (_.has(this.clientData, "itemDescription")) {
        this.Payload.itemDescription = this.clientData["itemDescription"];
      }
      //NAICSCode
      if (_.has(this.clientData, "NAICSCode")) {
        this.Payload.NAICSCode = this.clientData["NAICSCode"];
      }

      if (_.has(this.clientData, "businessTypeDetails")) {
        this.Payload.businessTypeDetails = this.clientData[
          "businessTypeDetails"
        ];
        this.selectedbType();
      }
      if (_.has(this.clientData, "marketPlaceSellDetails")) {
        this.Payload.marketPlaceSellDetails = this.clientData[
          "marketPlaceSellDetails"
        ];
      }

      if (_.has(this.clientData, "marketPlaceSellId")) {
        this.Payload.marketPlaceSellId = this.clientData["marketPlaceSellId"];
      }

      if (_.has(this.clientData, "otherMarketPlace")) {
        this.Payload.otherMarketPlace = this.clientData["otherMarketPlace"];
      }

      // busTypeId

      // Commented out until more platforms are added
      // if (_.has(this.clientData, "sellingPlatformIds")) {
      //   this.Payload.sellingPlatformIds = this.clientData["sellingPlatformIds"];
      //   if (this.Payload.sellingPlatformIds.length > 0) {
      //     this.Payload.platformDetails = [];
      //     _.forEach(this.platform, (item) => {
      //       if (this.Payload.sellingPlatformIds.indexOf(item._id) > -1) {
      //         this.Payload.platformDetails.push(item);
      //       }
      //     });
      //   }
      // }

      this.Payload.platformDetails = [
        { name: "Shopify", selected: true, _id: 1 },
      ];

      //identifiers
      if (_.has(this.clientData, "identifiers")) {
        if (this.clientData.identifiers.length > 0) {
          //// clientKeyType:'text',
          // clientSecretType:'text'//
          this.Payload.identifiers = this.clientData.identifiers.map((item) => {
            return Object.assign(item, {
              clientKeyType: "password",
              clientSecretType: "password",
            });
          });
        }
      }

      //"marketPlaceFecilitator": true, "sellOnMarketPlace": false, "purchaseExistingBusiness": false,
      if (_.has(this.clientData, "marketPlaceFecilitator")) {
        this.Payload.marketPlaceFecilitator = this.clientData.marketPlaceFecilitator;
      }
      this.Payload.involvedInMerge = false;
      if (_.has(this.clientData, "involvedInMerge")) {
        this.Payload.involvedInMerge = this.clientData.involvedInMerge;
      }

      if (_.has(this.clientData, "sellOnMarketPlace")) {
        this.Payload.sellOnMarketPlace = this.clientData.sellOnMarketPlace;
      }

      if (_.has(this.clientData, "shopifyUrlOrAddress")) {
        this.Payload.shopifyUrlOrAddress = this.clientData.shopifyUrlOrAddress;
      }
      /* need to discuss with satish
     ///If So, Please List
    if(_.has(this.clientData ,"sellOnMarketPlace")){
            this.Payload.sellOnMarketPlace = this.clientData.sellOnMarketPlace;
        }
    /*/

      if (_.has(this.clientData, "purchaseExistingBusiness")) {
        this.Payload.purchaseExistingBusiness = this.clientData.purchaseExistingBusiness;
      }

      if (_.has(this.clientData, "purchaseAgreementDocs")) {
        this.Payload.purchaseAgreementDocs = this.clientData.purchaseAgreementDocs;
        this.purchaseAgreementDocs = "purchaseAgreementDocs";
        if (this.Payload.purchaseAgreementDocs.length <= 0) {
          this.purchaseAgreementDocs = "";
        }
      }
      if (_.has(this.clientData, "haveEmployeesOrAgents")) {
        this.Payload.haveEmployeesOrAgents = this.clientData.haveEmployeesOrAgents;
      }
      if (_.has(this.clientData, "isSubsidaryOfParentCompany")) {
        this.Payload.isSubsidaryOfParentCompany = this.clientData.isSubsidaryOfParentCompany;
      }
      if (_.has(this.clientData, "parentCompanyName")) {
        this.Payload.parentCompanyName = this.clientData.parentCompanyName;
      }

      //otherBusStates
      if (_.has(this.clientData, "otherBusStates")) {
        this.Payload.otherBusStateIds = [];
        this.Payload.otherBusStates = this.clientData["otherBusStates"];
        _.forEach(this.Payload.otherBusStates, (state) => {
          this.Payload.otherBusStateIds.push(state["_id"]);
        });
        //  "otherBusStateIds": [], /// master
        // otherBusStates:null,
      }
      // empPayrollStateIds empPayrollStateDetails updateEmpPayrollStates
      this.Payload.empPayrollStateIds = [];
      this.Payload.empPayrollStateDetails = [];
      if (_.has(this.clientData, "empPayrollStateDetails")) {
        this.Payload.empPayrollStateDetails = this.clientData[
          "empPayrollStateDetails"
        ];
        _.forEach(this.Payload.empPayrollStateDetails, (state) => {
          this.Payload.empPayrollStateIds.push(state["_id"]);
        });
        //  "otherBusStateIds": [], /// master
        // otherBusStates:null,
      }

      if (_.has(this.clientData, "website")) {
        this.Payload.website = this.clientData.website;
      }

      if (_.has(this.clientData, "taxNumber")) {
        this.Payload.taxNumber = this.clientData.taxNumber;
      }

      if (_.has(this.clientData, "banks")) {
        this.Payload.banks = this.clientData["banks"];
        this.Payload.bankDetails = [];

        if (this.Payload.banks.length > 0) {
          _.forEach(this.Payload.banks, (b, index) => {
            let bank = _.find(this.bank, { _id: b.id });

            if (bank) {
              bank["selected"] = true;
              this.Payload.bankDetails.push(bank);
              this.Payload.banks[index]["name"] = bank["name"];
            }
          });
        }
      }

      if (
        _.has(this.clientData, "supplier") &&
        this.clientData["supplier"].length > 0
      ) {
        this.ClientDataloaded = false;
        let sup = _.cloneDeep(this.clientData["supplier"]);
        this.Payload.supplier = [];
        _.forEach(sup, (item) => {
          this.log(item.phone);
          //supplierTypes
          if (
            _.has(item, "typeId") &&
            _.has(this.clientData, "supplierTypes")
          ) {
            item = Object.assign(item, {
              typeDetails:
                _.find(this.clientData["supplierTypes"], {
                  _id: item["typeId"],
                }) || null,
            });
          }

          this.Payload.supplier.push(item);
        });
      }
      ///owners

      if (
        _.has(this.clientData, "inventoryLocations") &&
        this.clientData["inventoryLocations"].length > 0
      ) {
        this.ClientDataloaded = false;
        let sup = _.cloneDeep(this.clientData["inventoryLocations"]);
        this.Payload.inventoryLocations = [];
        _.forEach(sup, (item) => {
          if (
            _.has(item, "typeId") &&
            _.has(this.clientData, "inventoryLocationTypes")
          ) {
            item = Object.assign(item, {
              typeDetails:
                _.find(this.clientData["inventoryLocationTypes"], {
                  _id: item["typeId"],
                }) || null,
            });
          }
          this.Payload.inventoryLocations.push(item);
        });
      }

      if (
        _.has(this.clientData, "owners") &&
        this.clientData["owners"].length > 0
      ) {
        this.ClientDataloaded = false;
        let sup = _.cloneDeep(this.clientData["owners"]);
        this.Payload.owners = [];
        _.forEach(sup, (item) => {
          this.Payload.owners.push(item);
        });

        // this.ClientDataloaded =true;
      }

      //stateRegistrations
      ///owners

      if (_.has(this.clientData, "stateReg")) {
        this.ClientDataloaded = false;
        let sup = _.cloneDeep(this.clientData["stateReg"]);
        this.Payload.stateReg = [];
        this.Payload.stateRegs = [];
        _.forEach(sup, (item) => {
          // "addstateReg" Payload.stateRegs
          let state = _.find(this.usstateList, { _id: item.stateId });

          if (state) {
            this.Payload.stateRegs.push(state);
          }
          item["show"] = false; //collapse state
          item["status"] = true;
          this.Payload.stateReg.push(item);
        });
        this.log(JSON.stringify(this.Payload.stateRegs));

        this.ClientDataloaded = true;
      }

      if (_.has(this.clientData, "logo")) {
        this.Payload.logo = this.clientData["logo"];
      }

      if (_.has(this.clientData, "doYouUserTaxzarApi")) {
        this.Payload.doYouUserTaxzarApi = this.clientData["doYouUserTaxzarApi"];
      }
      //taxJarDescription
      if (_.has(this.clientData, "taxJarDescription")) {
        this.Payload.taxJarDescription = this.clientData["taxJarDescription"];
      }
      if (_.has(this.clientData, "havePhysicalStore")) {
        this.Payload.havePhysicalStore = this.clientData["havePhysicalStore"];
      }

      if (_.has(this.clientData, "physicalStoreAddress")) {
        this.Payload.physicalStoreAddress = this.clientData[
          "physicalStoreAddress"
        ];
      }

      if (_.has(this.clientData, "shopify")) {
        this.Payload.shopify = this.clientData["shopify"];
      }

      this.ClientDataloaded = true;
    },
    checkLlc(e, item) {
      //  console.log(e);
      //if(_.has(item ,"selected")){

      if (e.target.checked) {
        if (this.Payload.llcTypeIds.indexOf(item._id) <= -1) {
          this.Payload.llcTypeIds.push(item._id);
        }
      } else {
        if (this.Payload.llcTypeIds.indexOf(item._id) >= -1) {
          item.selected = false;
          this.Payload.llcTypeIds.splice(
            this.Payload.llcTypeIds.indexOf(item._id),
            1
          );
        }
      }

      //}
    },

    findUsedStates(item) {
      console.log(item);
      this.filteredUsstateList = this.usstateList;
      let returnStates = this.usstateList;
      let usedStates = this.Payload.previousReturnNotifyStates.map((item) => {
        if (_.has(item, "stateDetails")) {
          if (_.has(item["stateDetails"], "_id")) {
            return item["stateDetails"]["_id"];
          }
        }
      });

      returnStates = _.filter(returnStates, (item) => {
        return usedStates.indexOf(item._id) <= -1;
      });
      this.filteredUsstateList = returnStates;

      //this.Payload.previousReturnNotifyStates
    },
  },
  computed: {
    checkShopyfy() {
      let returnVal = false;
      if (_.has(this.Payload, "platformDetails")) {
        if (_.find(this.Payload.platformDetails, { _id: 1 })) {
          returnVal = true;
        }
      }
      return returnVal;
    },
    checkCaliforniaState() {
      if (
        this.Payload.incorporatedStateDetails != null &&
        _.has(this.Payload.incorporatedStateDetails, "_id")
      ) {
        // if(this.Payload.incorporatedStateDetails['_id'] == 3924 ){
        if (this.Payload.incorporatedStateDetails) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    showLoading: {
      // return this.$store.getters["isloading"];
      //return false;
      get() {
        /// return false;
        return this.$store.getters["isloading"];
      },
      set(value) {
        this.value = value;
      },
    },
    checkValidate() {
      let keys = Object.keys(this.blocks);
      let i = 0;
      _.forEach(this.blocks, (item) => {
        if (item.formIsValid) i = i + 1;
      });
      this.log(i);
      if (i >= keys.length) {
        return true;
      } else {
        return false;
      }
    },
  },
  beforeCreate() {
    //console.log(this.showLoading);
  },
};
</script>

<style scoped>
.side-padding {
  padding: 0 10px;
}

.checkbox-label {
  display: block;
  margin-bottom: 10px;
}
.form-control-inline {
  margin-left: 10px;
  display: inline-block;
  width: auto;
}
</style>

<style scoped>
.checkbox-label {
  display: block;
  margin-bottom: 10px;
}
.form-control-inline {
  margin-left: 10px;
  display: inline-block;
  width: auto;
}

.radio-group-platform {
  display: flex;
  flex-direction: column;
}

.other-radio {
  display: flex;
  align-items: center;
}

.radio-item {
  display: flex;
  gap: 8px; /* Adjust the space between the radio and label as needed */
}

.radio-input {
  position: relative;
  height: 24px;
  width: 24px;
  border: 1px solid rgba(25, 33, 62, 0.4);
  border-radius: 50%;
  appearance: none;
  outline: none;
  background-color: white;
  cursor: pointer;
}

.radio-input::after {
  content: "";
  position: absolute;
  top: 29%;
  left: 50%;
  width: 10px; /* Adjust this for the size of the checkmark */
  height: 5px;
  border: solid white;
  border-width: 0 0 2px 2px;
  transform: rotate(-45deg) translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.radio-input:checked {
  background-color: rgba(
    25,
    33,
    62,
    1
  ); /* Background color for the checked state */
  border-color: rgba(25, 33, 62, 1);
}

.radio-input:checked::after {
  opacity: 1; /* Show the checkmark when checked */
}

.other-input {
  width: 200px; /* Adjust width as necessary */
}
.css-space-right {
  margin-right: 6px;
}
</style>
