export const Config = {
  taxheroMailingAddress: {
    addr1: '',
    addr2: '11816 Inwood Rd, PMB 5062',
    zip: '75244',
    cntryId: 231,
    stateId: 3970,
    cityId: 46334,
    countryDetails: { _id: 231, name: 'United States' },
    stateDetails: { _id: 3970, name: 'Texas' },
    locationDetails: { _id: 46334, name: 'Dallas' },
  },
};
